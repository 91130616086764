import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Logo } from "./Logo";
import { Menu } from "./Menu/Menu";
import { Support } from "./Support/Support";
import { FisheriesSelector } from "./FisheriesSelector";
import { APIContext } from '../../../utils/APIContext';

import './VerticalNavbar.css';

const firstMenu = [
    {path:'/lk/fisheries', icon: 'home-icon', title: 'Главная'},
    {path:'/lk/ponds', icon: 'pond-icon', title: 'Пруды'},
    {path:'/lk/research', icon: 'research-icon', title: 'Контрольный отлов'}
]

const researchSubMenu = [
    {path:'/lk/research/water', icon: '', title: 'Анализ воды'},
    {path:'#', icon: '', title: 'Кормление'},
    {path:'#', icon: '', title: 'Заболевания'}
]

const secondMenu = [
    {path:'/lk/seeding', icon: 'fish-icon', title: 'Зарыбление'},
    {path:'/lk/catch', icon: 'catch-icon', title: 'Облов'}
]

const fisheriesMenu = [
    {path:'/lk/settings/fisheries', icon: 'master-user-icon', title: 'Хозяйства'},
    {path:'/lk/settings/ponds', icon: 'master-user-icon', title: 'Пруды'},
]

const masterMenu = [
    {path:'/lk/admin/user', icon: 'master-user-icon', title: 'Пользователи'},
    {path:'/lk/admin/fisheries', icon: 'master-user-icon', title: 'Хозяйства'}
]

const support = [
    // {path:'#', icon: 'support-phone-icon', title: '8 800 000 00 00', subtitle: 'телефон службы поддержки'},
    {path:'mailto:support@agrointellect.ru', icon: 'support-phone-icon', title: 'support@agrointellect.ru', subtitle: 'почта службы поддержки'}
]

export class VerticalNavbar extends React.Component {    
    static contextType = APIContext;

    render() {
        return (
            <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
                <div className="sidebar-menu-content">
                    <Logo />       
                    {this.props.fisheriesData && this.props.fisheriesData.length > 1 && (
                        <FisheriesSelector 
                            fisheriesData={this.props.fisheriesData} 
                            activeFisheries={this.props.activeFisheries} 
                            selectActiveFisheries={this.props.selectActiveFisheries}
                        />        
                    )} 
                    <Scrollbar noDefaultStyles style={{ width: "100%", height: "100%" }}>
                        <Menu items={firstMenu} class="first" subMenu={researchSubMenu}/>
                        <Menu items={secondMenu} class="second"/>                    
                        {this.context.allowFisheriesEdit && (         
                            <div className="sidebar-additional-menu">           
                                <div className='sidebar-menu-title'>Настройки</div>
                                <Menu items={fisheriesMenu} />
                            </div>
                        )}
                        {this.context.allowMasterEdit && (       
                            <div className="sidebar-additional-menu">           
                                <div  className='sidebar-menu-title'>Администрирование</div>
                                <Menu items={masterMenu} />
                            </div>             
                        )}                        
                    </Scrollbar>
                    <Support items={support} class="support"/>                
                    <div className='exitButton' onClick={this.props.terminateSession}>Завершить сеанс</div> 
                    
                </div>
            </div>
        );
    }
}
