import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { CardExtended } from "../Elements/Card/Extended";

export class PondBlock extends React.Component {    
    render() {
        return (   
            <div className='block-container'>
                <div className='dashboard-title-sub'>{this.props.name}</div>
                <Scrollbar noDefaultStyles style={{ width: "100%", height: "320px" }}>
                    <div className='card-container-wrapper'>                        
                        <div className='card-container extended-container'>
                            {this.props.pondsData && this.props.pondsData.map((item, index) => 
                                item.type_id === this.props.typeId && (
                                    <CardExtended item={item} id={index} key={index} size='flex' clickable={true} handler={this.props.selectActivePond}/>
                                )
                            )}
                        </div>
                    </div>
                </Scrollbar>
            </div>
        );
    }
}
