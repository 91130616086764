export function validateNotEmpty(value) {
    if (typeof(value) === 'number') {
        return true;
    }
    return value.length > 0;
}

export function validateEmail(value) {
    const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

export function validatePhone(value) {
    const re = /^([+\-0-9\\(\\)]{4,16})$/;
    return re.test(String(value).toLowerCase());
}

export function validateDate(value) {
    const re = /^([0-9.]{10})+$/;
    return re.test(String(value).toLowerCase());
}

export function validateName(value) {
    const re = /^([A-Za-zА-Яа-я\- ])+$/;
    return re.test(String(value).toLowerCase());
}

export function validateCity(value) {
    const re = /^([A-Za-zА-Яа-я\-,.:0-9 ])+$/;
    return re.test(String(value).toLowerCase());
}

export function validateNumber(value) {
    const re = /^([0-9])+$/;
    return re.test(String(value).toLowerCase());
}

export function validateNumber9(value) {
    const re = /^([0-9]){9}$/;
    return re.test(String(value).toLowerCase());
}

export function validateNumber20(value) {
    const re = /^([0-9]){20}$/;
    return re.test(String(value).toLowerCase());
}