import React from "react";

import './FisheriesSelector.css'

export class FisheriesSelector extends React.Component {
    constructor() {
      super();
      this.changeHandler = this.changeHandler.bind(this);
    }
    
    state = {
        activeFisheries: null
    }

    componentDidUpdate() {
        if (this.props.activeFisheries !== this.state.activeFisheries) {
            this.setState({
                activeFisheries: this.props.activeFisheries
            });
        }
    }

    changeHandler(e) {
        this.props.selectActiveFisheries(e, e.target.value);
    }

    render() {
        return (
            <select className="sidebar-selector" value={this.state.activeFisheries === null ? '-1' : `${this.state.activeFisheries}`} onChange={this.changeHandler}>
                {this.props.activeFisheries === null && (<option value='-1'>Выберите хозяйство</option>)}
                {this.props.fisheriesData && this.props.fisheriesData.map((item, index) => <option value={index} key={item.id}>{item.name}</option>)}    
            </select>
        );
    }
}
