import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../Elements/Button/Button";
import { Modal } from "../../Elements/Modal/Modal";
import { Input } from '../../Elements/Input/Input';
import { Select } from "../../Elements/Select/Select";

import { sendRequest } from '../../../utils/XHR';
import { validateEmail, validateNotEmpty } from '../../../utils/Validator';

const roleList = [
    {title : 'Администратор', level: 3},
    {title :'Пользователь', level: 1}
]

export class AboutUserWindow extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
    }  

    state = {
        saveSuccess: false,
        errorState: false,
        errorText: '',
        loginValue: '',
        passwordValue: '',
        accessValue: 0,
        showModal: false
    }

    componentDidMount() {
        for (const item in roleList) {
            if (roleList[item].title === this.props.initValue['user_level']) {
                this.setState({
                    accessValue: item
                })
                break;
            }
        }
        this.setState({
            loginValue: this.props.initValue['login'],
        })
    }

    updateUser() {
        if (!validateNotEmpty(this.state.loginValue)) {
            this.setState({
                errorText: 'Логин не может быть пустым',
                errorState: true
            });
            return;
        }
        if (!validateEmail(this.state.loginValue)) {
            this.setState({
                errorText: 'Не верный формат логина. Должен быть e-mail',
                errorState: true
            });
            return;
        }
        let formData = new FormData();
        formData.append('login', this.props.initValue['login']);
        if (this.state.loginValue === this.props.initValue['login']) {
            formData.append('new_login', this.state.loginValue);
        }
        if (this.state.passwordValue.length > 0) {
            formData.append('password', this.state.passwordValue);
        }
        formData.append('user_level', roleList[this.state.accessValue].level);
        
        let response = sendRequest(formData, '/user/update-by/login');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)
            this.props.updateCallback && this.props.updateCallback(result);
        });  
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    selectHandler(_e, index) {
        this.setState({
            accessValue: index
        })
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

	render() {
		return (
           <div className='research-modal'>               
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}               
                <div className='title-modal'>
                    Подробнее о пользователе
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>
                    <div className='row-modal'>
                        <div className='input-column'>
                            <div className='row-modal wrap'>
                                <Input title='Логин' value={this.state.loginValue} changeHandler={(e) => this.changeHandler(e, 'loginValue')} fullWidth={true} />
                            </div>                 
                        </div>             
                    </div>
                        
                    <div className='row-modal'>
                        <div className='input-column'>
                            <div className='row-modal wrap'>
                                <Input title='Пароль' type='password' value={this.state.passwordValue} changeHandler={(e) => this.changeHandler(e, 'passwordValue')} fullWidth={true} />
                            </div>                 
                        </div>             
                    </div>
                        
                    <div className='row-modal'>
                        <div className='input-column'>
                            <div className='sub-title'>
                                Роль в системе
                            </div>
                            
                            <Select 
                                active={roleList[this.state.accessValue].title} 
                                data={roleList} 
                                handler={(e, index) => this.selectHandler(e, index)}
                                class={'full-width-select'}
                            />             
                        </div>
                    </div>
                    <div className='row-modal'>                        
                        <div className='input-column'> 
                            {(this.state.saveSuccess) ? (
                                <div className='succes-block'>
                                    <div className='success-icon'></div>
                                    <div className='success-text'>Изменения сохранены</div>                           
                                </div>
                            ) : (
                                <Button title='Сохранить изменения' handler={() => this.updateUser()} />
                            )}
                        </div>   
                    </div>           
                </Scrollbar>
           </div>
		);
	}
}
