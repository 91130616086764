import React from "react";
import { Link } from "react-router-dom";

export class Logo extends React.Component {
  render() {
    return (
        <div className="header-logo">
            <Link to="/lk">
                <img src={window.location.origin + '/img/logo1.png'}  alt="logo"/>
            </Link>
        </div>
    );
  }
}
