import React from "react";

import { Input } from "../../Elements/Input/Input";
import { Button } from "../../Elements/Button/Button";
import { Table } from "../../Elements/Table/Table";
import { Modal } from "../../Elements/Modal/Modal";

import { EditWindow } from "./EditWindow";

import { sendRequest } from '../../../utils/XHR';

const pondTypeTableHeader = [
    {name: 'name', text: 'Название'},
    {name: 'count', text: 'Количество'},
    {name: 'change', text: '',  useButton: true, isEdit: true, isEditText:'Редактировать' },
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Удалить' },
];

const pondTypeTableColumnStyle = {
    name: 'one', 
    count: 'one', 
    change: 'one', 
    remove: 'one', 
}

export class PondType extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.openEditWindow = this.openEditWindow.bind(this);
        this.closeEditWindow = this.closeEditWindow.bind(this);
      

        this.getPondTypeData = this.getPondTypeData.bind(this);
        this.calculatePondTypeTableData = this.calculatePondTypeTableData.bind(this);
        this.addPondType = this.addPondType.bind(this);
        this.removePondType = this.removePondType.bind(this);   
        this.updatePondType = this.updatePondType.bind(this);       
  
    }  

    state = {
        errorText: '',
        errorState: false,
        
        saveSuccess: false,

        showModal: false,
        editValue: null,

        pondTypeData: [],
        pondTypeNameValue: '',
    }

    componentDidMount() {       
        if (this.props.id) {
            this.getPondTypeData();
        }
    }

    getPondTypeData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/pond_type/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }         
            this.calculatePondTypeTableData(result);
        });
    }

    calculatePondTypeTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                count: data[item]['pond_count'],
                name: data[item]['name'],
            }
            const forChange = {
                data: [
                    {
                        title: 'Навзание',
                        value: data[item]['name'],
                        valueName: 'nameValue'
                    }
                ],
                id: data[item]['id']
            }
            element['change'] = {handler: ((e) => {this.openEditWindow(forChange)})} || ''
            
            element['remove'] = {handler: ((e) => {this.removePondType(data[item]['id'])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.pondTypeData)) {
            this.setState({
                pondTypeData: newData
            })  
        }
    }

    addPondType() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', this.state.pondTypeNameValue);
        let response = sendRequest(formData, '/pond_type/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }     
            this.props.onChangeSettings();       
            this.calculatePondTypeTableData(result);
        });
    }

    removePondType(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/pond_type/delete');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }       
            this.props.onChangeSettings();         
            this.calculatePondTypeTableData(result);
        });
    }

    updatePondType(args) {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);        
        formData.append('name', args.nameValue);
        formData.append('id', args.id);
        let response = sendRequest(formData, '/pond_type/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }      
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)
            this.props.onChangeSettings();      
            this.calculatePondTypeTableData(result);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    openEditWindow(value) {
        this.setState({
            showModal: true,
            editValue: value
        })
    }

    closeEditWindow() {
        this.setState({
            showModal: false,
            editValue: null
        })

    }

    render() {
        return (      
            <div className='block-container'>
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeEditWindow} 
                        content={<EditWindow 
                                    title='Редактирование типа пруда'
                                    initValue={this.state.editValue}
                                    updateCallback={(args) => this.updatePondType(args)}
                                />} 
                        />
                )}
                <div className='dashboard-title-sub'>Типы прудов</div>                       
                <div className='card-container'>                        
                    <Table 
                        headers={pondTypeTableHeader}
                        data={this.state.pondTypeData} 
                        style={pondTypeTableColumnStyle} 
                        tableHeight={300} 
                    />
                </div>
                <div className='fast-add-container'>
                    <Input title='Название' value={this.state.pondTypeNameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'pondTypeNameValue')} />                    
                    {(this.state.saveSuccess) ? (
                        <div className='succes-block'>
                            <div className='success-icon'></div>
                            <div className='success-text'>Тип пруда добавлен</div>                           
                        </div>
                    ) : (
                        <Button title='Добавить тип пруда' icon='button-plus-icon'  handler={() => this.addPondType()} />
                    )}
                </div>
            </div>
        );
    }
}
