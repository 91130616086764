import React from "react";

export class Item extends React.Component {
  render() {
    return (                     
        <li className= "nav-item sidebar-nav-item">
            <a href={this.props.path} className="nav-link">
                <i className={this.props.icon}></i>
                <div className="support-block">
                  <span className="title">{this.props.title}</span>
                  <span className="subtitle">{this.props.subtitle}</span>
                </div>
            </a>
        </li>      
    );
  }
}
