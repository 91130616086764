import React from "react";

import "./Select.css";

export class Select extends React.Component {    
    constructor(props) {
        super(props);
        this.openSelect = this.openSelect.bind(this);
        this.closeSelect = this.closeSelect.bind(this);
        this.changeSelection = this.changeSelection.bind(this);
    }  

    state = {
        isOpen: false
    }

    openSelect() {
        this.setState({
            isOpen: true
        })
    }

    closeSelect() {
        this.setState({
            isOpen: false
        })
    }

    changeSelection(e, index) {
        this.props.handler(e, index);
        this.closeSelect()
    }


    render() {
        return (
            <div className={`select-container ${this.props.class}`}>
                <div className='title-container' onMouseDown={this.openSelect}>
                    <div className='active-title'>
                        {this.props.active}
                    </div>
                    <div className='drop-icon'>
                    </div>
                </div>
                {this.state.isOpen && (
                    <div className='dropdown-container' onMouseLeave={this.closeSelect}>
                        <div className='dropdown'>
                            {this.props.data && this.props.data.map((item, index) => (
                                <div className='dropdown-row' onClick={(e) => this.changeSelection(e, index)} key={index}>
                                    {item.title}
                                </div>)
                            )}  
                            
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
