import React from "react";

import { Button } from "../Elements/Button/Button";
import { Modal } from "../Elements/Modal/Modal";
import { Input } from '../Elements/Input/Input';
import { Table } from "../Elements/Table/Table";

import { AboutUserWindow } from "./Users/AboutUserWindow";

import { sendRequest } from '../../utils/XHR';
import { validateEmail, validateNotEmpty } from '../../utils/Validator';

import './AdminPage.css'

const tableHeader = [
    {name: 'login', text: 'Логин'},
    {name: 'accessLevel', text: 'Уровень доступа'}, 
    // {name: 'fisheriesCount', text: 'Количество хозяйств'},
    {name: 'change', text: '', useButton: true, isEdit: true}
];
const tableColumnStyle = {
    login: 'one', 
    accessLevel: 'two', 
    fisheriesCount: 'three',
    change: 'four',
}

export class AdminUserPage extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.getUsersData = this.getUsersData.bind(this);
        this.calculateTableData = this.calculateTableData.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.openAboutWindow = this.openAboutWindow.bind(this);
        this.closeAboutWindow = this.closeAboutWindow.bind(this);
    }
    state = {
        userData: [],
        userDataLast: [],
        waitXHR: false,
        loginValue: '',
        passwordValue: '',
        errorText: '',
        errorState: false,
        offset: 0
    } 
    componentDidMount() {
        this.getUsersData(false);
    }

    getUsersData(needMerge) {
        if (this.state.offset < 0) {
            return;
        }
        let formData = new FormData();
        formData.append('offset', this.state.offset);
        let response = sendRequest(formData, '/user/get-all');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                    waitXHR: false
                });
                return;
            }                
            this.setState({
                waitXHR: false,
                offset: this.state.offset + result.length
            });
            this.calculateTableData(result, needMerge);
        });
    }

    calculateTableData(data, needMerge, force) {
        let newData = [];
        for (const item in data) {
            if (data[item]['_$actual']) {
                newData.push(data[item]); 
                continue;
            }
            let element = {
                id: data[item]['id'],
                login: data[item]['login'],
                accessLevel: data[item]['user_level'],
                fisheriesCount: 0,
                _$actual: true
            }            
            element['change'] = {handler: ((e) => {this.openAboutWindow(data[item])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.userDataLast) || force) {
            let fullData = newData;
            if (needMerge) {
                fullData = this.state.userData;
                fullData = fullData.concat(newData);
            }
            this.setState({
                userData: fullData,
                userDataLast: newData
            })  
            return;  
        }
        if (needMerge) {
            this.setState({
                offset: -1
            })  
        }
    }

    createUser() {        
        if (!validateNotEmpty(this.state.loginValue)) {
            this.setState({
                errorText: 'Логин не может быть пустым',
                errorState: true
            });
            return;
        }
        if (!validateNotEmpty(this.state.passwordValue)) {
            this.setState({
                errorText: 'Пароль не может быть пустым',
                errorState: true
            });
            return;
        }
        if (!validateEmail(this.state.loginValue)) {
            this.setState({
                errorText: 'Не верный формат логина. Должен быть e-mail',
                errorState: true
            });
            return;
        }
        let formData = new FormData();
        formData.append('login', this.state.loginValue);
        formData.append('password', this.state.passwordValue);
        let response = sendRequest(formData, '/user/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }                  
            this.setState({
                loginValue: '',
                passwordValue: ''
            });  
            this.getUsersData(true);
        });
    }
    
    updateCallback(changed) {
        const newData = [...this.state.userData];
        for (const index in newData) {
            if (newData[index]['id'] === changed['id']) {
                newData[index] = changed
            }
        }
        this.calculateTableData(newData, false, true);
    }

    openAboutWindow(value) {  
        this.setState({
            showModal: true,
            aboutValue: value,
        });
    }

    closeAboutWindow() {
        this.setState({
            showModal: false,
            aboutValue: null
        })
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    render() {        
        return (                
            <div className='page-container admin-page'> 
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeAboutWindow} 
                        content={<AboutUserWindow 
                                    initValue={this.state.aboutValue}
                                    updateCallback={(changed) => this.updateCallback(changed)}
                                />} 
                        />
                )}
                <div className='dashboard-title'>
                    Управление пользователями
                </div>
                <div className='block-container'>
                    <div className='dashboard-title-sub'>Список пользователей</div>                    
                    <div className='card-container'>
                        <Table 
                            headers={tableHeader}
                            data={this.state.userData} 
                            style={tableColumnStyle} 
                            tableHeight={580} 
                            bigContainer={true}
                            onScrollEndHandler={() => this.getUsersData(true)}
                        />                       
                    </div>
                    <div className='fast-add-container'>
                        <Input title='Логин' value={this.state.loginValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'loginValue')} />
                        <Input title='Пароль' value={this.state.passwordValue} fullWidth={true} type='password' changeHandler={(e) => this.changeHandler(e, 'passwordValue')} />
                        <Button title='Добавить пользователя' icon='button-plus-icon' handler={() => this.createUser()} />
                    </div>
                </div>
            </div>
        );
    }
}
