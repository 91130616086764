import React from "react";

import { Modal } from '../Elements/Modal/Modal';
import { Button } from "../Elements/Button/Button";

import { saveAPIToken } from '../../utils/Cookie';
import { sendRequest } from '../../utils/XHR';


import './AuthPage.css'

export class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.loginChange = this.loginChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getAuthData = this.getAuthData.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.formRef = React.createRef();
    }

	state = {
        login: '',
        password: '',
        response: '',
        errorState: false
	};

    loginChange(event) {
        this.setState({login: event.target.value});
    }

    passwordChange(event) {
        this.setState({password: event.target.value});
    }

    handleSubmit(event) {
        let formData = new FormData();
        formData.append('login', this.state.login);
        formData.append('password', this.state.password);
        this.getAuthData(formData);
        event.preventDefault();
    }

    /* Метод получает данные авторизации по API
    */
    getAuthData(params) {
        let response = sendRequest(params, '/user/auth');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    response: error,
                    errorState: true
                });
                return;
            }            
            saveAPIToken(result.api_token);
            this.props.setAuth(true, result);
        })
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

 	render() {
        return (
            <div className="login-page-wrap">
                {this.state.errorState && <Modal text={this.state.response} status="error" onClose={this.resetErrorState}/>}
                <div className="login-page-content">
                    <div className="login-box">
                        <div className="item-logo">
                            <img src="img/logo2.png" alt="logo" />
                        </div>
                        <form className="login-form" onSubmit={this.handleSubmit} method="post" >
                            <div className="form-group">
                                <label>Логин</label>
                                <input type="email" value={this.state.login} onChange={this.loginChange} required autoFocus placeholder="Логин" className="form-control"/>                                
                                <i className="email-icon"></i>
                            </div>
                            <div className="form-group">
                                <label>Пароль</label>
                                <input type="password" value={this.state.password} onChange={this.passwordChange} required placeholder="Пароль" className="form-control"/>
                                <i className="password-icon"></i>
                            </div>
                            <div className="form-group">
                                <Button title='Авторизоваться' class='full-widgth'  handler={(e) => {this.formRef.current.click()}} />
                                <button type='submit'ref={this.formRef} className='hidden' ></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
