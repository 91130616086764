import Config from './Config';
import { getCookie } from './Cookie';

/* Метод для отправки запросов на сервер API
*/
export function sendRequest(params, customUrl) {
    const response = fetch(Config.URL + customUrl || Config.URL, {
            method: 'POST',
            headers: {
                'api-token': getCookie('api-token')
            },
            redirect: 'follow',
            mode: 'cors', // no-cors
            body: params
    });
    return response.then(response => response.text()).then((result) => {
        return JSON.parse(result);  
    });
}
