import React from "react";

import { Input } from "../../Elements/Input/Input";
import { Button } from "../../Elements/Button/Button";
import { Table } from "../../Elements/Table/Table";
import { Modal } from "../../Elements/Modal/Modal";

import { EditWindow } from "./EditWindow";

import { sendRequest } from '../../../utils/XHR';

const fishTypeTableHeader = [
    {name: 'name', text: 'Название'},
    {name: 'change', text: '',  useButton: true, isEdit: true, isEditText: 'Редактировать' },
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Удалить' },
];

const fishTypeTableColumnStyle = {
    name: 'one', 
    change: 'one', 
    remove: 'one', 
}

export class Fish extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.openEditWindow = this.openEditWindow.bind(this);
        this.closeEditWindow = this.closeEditWindow.bind(this);
       
        this.getFishData = this.getFishData.bind(this);
        this.calculateFishTableData = this.calculateFishTableData.bind(this);
        this.addFishType = this.addFishType.bind(this);
        this.removeFishType = this.removeFishType.bind(this);   
        this.updateFishType = this.updateFishType.bind(this);       
  
    }  

    state = {
        errorText: '',
        errorState: false,
        
        saveSuccess: false,

        showModal: false,
        editValue: null,

        fishData: [],
        fishNameValue: '',
    }

    componentDidMount() {
        if (this.props.id) {
            this.getFishData();
        }
    }

    getFishData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/fish/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.calculateFishTableData(result);
        });
    }

    calculateFishTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                name: data[item]['name'],
            }
            const forChange = {
                data: [
                    {
                        title: 'Название',
                        value: data[item]['name'],
                        valueName: 'nameValue'
                    }
                ],
                id: data[item]['id']
            }
            element['change'] = {handler: ((e) => {this.openEditWindow(forChange)})} || ''
            
            element['remove'] = {handler: ((e) => {this.removeFishType(data[item]['id'])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.fishData)) {
            this.setState({
                fishData: newData
            })  
        }
    }

    addFishType() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', this.state.fishNameValue);
        let response = sendRequest(formData, '/fish/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }       
            this.props.onChangeSettings();        
            this.calculateFishTableData(result);
        });
    }

    removeFishType(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/fish/delete');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }           
            this.props.onChangeSettings();        
            this.calculateFishTableData(result);
        });
    }

    updateFishType(args) {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', args.nameValue);
        formData.append('id', args.id);
        let response = sendRequest(formData, '/fish/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }  
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)
            this.props.onChangeSettings();             
            this.calculateFishTableData(result);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    openEditWindow(value) {
        this.setState({
            showModal: true,
            editValue: value
        })
    }

    closeEditWindow() {
        this.setState({
            showModal: false,
            editValue: null
        })

    }

    render() {
        return (               
            <div className='block-container'>
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeEditWindow} 
                        content={<EditWindow 
                                    title='Редактирование рыбы'
                                    initValue={this.state.editValue}
                                    updateCallback={(args) => this.updateFishType(args)}
                                />} 
                        />
                )}
                <div className='dashboard-title-sub'>Виды рыбы</div>                    
                <div className='card-container'>                        
                    <Table 
                        headers={fishTypeTableHeader}
                        data={this.state.fishData} 
                        style={fishTypeTableColumnStyle} 
                        tableHeight={300} 
                    />
                </div>
                <div className='fast-add-container'>
                    <Input title='Название' value={this.state.fishNameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'fishNameValue')} />
                    {(this.state.saveSuccess) ? (
                        <div className='succes-block'>
                            <div className='success-icon'></div>
                            <div className='success-text'>Вид рыбы добавлен</div>                           
                        </div>
                    ) : (
                        <Button title='Добавить вид рыбы' icon='button-plus-icon'  handler={() => this.addFishType()} />
                    )}</div>
            </div>
        );
    }
}
