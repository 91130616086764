import Cookies from 'universal-cookie';
import Config from './Config';

const cookies = new Cookies();

/* Хелперы для работы с куками
*/

/* Метод для получения куки по ее имени
*/
export function getCookie(name) {
    return cookies.get(name);
}

/* Метод для проверки наличия куки по ее имени
*/
export function checkCookie(name) {
    return !!cookies.get(name);
}

/* Метод для установки значения куки по ее имени
*/
export function setCookie(name, value) {
    cookies.set(name, value, { path: '/' , expires: new Date(Date.now() + Config.coockieTime)});
}

/* Метод для удлаления куки по ее имени
*/
export function removeCookie(name) {
    cookies.remove(name, { path: '/' });
}

/* Метод сохраняет данные успешной авторизации
*/
export function saveAPIToken(token) {
    setCookie('api-token', token);
}

/* Метод очищает все данные для текущей сессии
*/
export function clearSession() {
    cookies.remove('token', { path: '/' });
    cookies.remove('userName', { path: '/' });
    Config.terminateSession && cookies.remove('sessionTime', { path: '/' });
    Config.saveSelectedFarm && cookies.remove('selectedFarm', { path: '/' });
    checkCookie('reportDateFrom') && cookies.remove('reportDateFrom', { path: '/' });
    checkCookie('reportDateTo') && cookies.remove('reportDateTo', { path: '/' })
}
