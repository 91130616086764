import React from "react";

import { Card } from "../Elements/Card/Card";
import { CardExtended } from "../Elements/Card/Extended";
import { Select } from "../Elements/Select/Select";
import { Button } from "../Elements/Button/Button";
import { Table } from "../Elements/Table/Table";
import { Modal } from "../Elements/Modal/Modal";
import { Graph } from "../Elements/Graph/Graph";
import { CreateWindow } from "../Elements/Extended/CreateResearch/CreateWindow";
import { CreateWaterWindow } from "../Elements/Extended/CreateResearch/CreateWaterWindow";
import { LoadingPage } from "../LoadingPage/LoadingPage";


import { sendRequest } from '../../utils/XHR';
import { beautifulDate } from '../../utils/Common';
import { APIContext } from '../../utils/APIContext';


export class PondPage extends React.Component {  
    static contextType = APIContext;

    constructor(props) {
        super(props);
        this.preparePondsData = this.preparePondsData.bind(this);
        this.openCreateWindow = this.openCreateWindow.bind(this);
        this.closeCreateWindow = this.closeCreateWindow.bind(this);
        this.createCallback = this.createCallback.bind(this);
        this.resetData = this.resetData.bind(this);
        this.createState = this.createState.bind(this);
        this.getResearchData = this.getResearchData.bind(this);
        this.getWaterData = this.getWaterData.bind(this);
        this.prepareWaterData = this.prepareWaterData.bind(this);
        this.openWaterCreateWindow = this.openWaterCreateWindow.bind(this);
        this.closeWaterCreateWindow = this.closeWaterCreateWindow.bind(this);
        this.tableHeader = [];
        this.tableColumnStyle = {};
        this.tableWaterHeader = [];
        this.tableWaterColumnStyle = {}
    }  

    state = {
        pondsData: [],
        pondData: [],
        waitXHR: false,
        showModal: false,
        showWaterModal: false,
        researchData: null,
        waterData: null,
        graphData: {},
        isCreate: true,
        initCreateValue: null
    }
   
    componentDidMount() {
        this.preparePondsData();
        this.prepareWaterData();
        if (this.props.pondData) {
            this.setState({ 
                waitXHR:true
            })
            this.getResearchData();            
            this.getWaterData();            
        }
    }

    componentDidUpdate() {
        this.preparePondsData();
        this.prepareWaterData();
        if (this.props.pondData && JSON.stringify(this.props.pondData) !== JSON.stringify(this.state.pondData)) {
            this.setState({
                pondData: this.props.pondData
            })
            this.resetData()
            if (!this.state.waitXHR){
                this.getResearchData()
                this.getWaterData()
            }
        }
    }

    calculateTableData(data) {
        const newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                date: beautifulDate(data[item]['date']),
                clinical: data[item]['clinical'] || '',
                conclusion: data[item]['conclusion'] || '',
                feed_ratio: data[item]['feed_ratio'] || 0,
                fullness: data[item]['fullness'] || 0,
                gills: data[item]['gills'] || '',
                pathogenesis: data[item]['pathogenesis'] || '',
                surface: data[item]['surface'] || '',
                averageWeigth: data[item]['average_weigth'] || 0,
                averageLength: data[item]['average_length'] || 0,
                averageKu: data[item]['average_ku'] || 0,
                weigthLengthData: data[item]['weigth_length_data'] || [],
            }
            if (this.context.allowEdit) {
                element['change'] = {handler: ((e) => {this.createState(e, false, element); this.openCreateWindow()})} || ''
            }
            newData.push(element);
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.researchData)) {
            this.setState({
                researchData: newData
            })        
        }
    }
    
    calculateGraphData(data) {
        const newData = [];
        for (const item in data) {
            if (data[item]['use_date']) {
                for (const item1 in data[item]['data']) {
                    data[item]['data'][item1][data[item]['use_date']] = beautifulDate(data[item]['data'][item1][data[item]['use_date']]);
                }
            }
            newData.push({
                    'id': data[item]['graph_id'],
                    'data': data[item]['data'],
                    'axisX': data[item]['x_name'],
                    'lineOne': data[item]['y0_name'],
                    'lineTwo': data[item]['y1_name'],
                    'graphTitle': data[item]['graph_title'],
                }
            )
        }

        let oldData = this.state.graphData;
        let wasChanged = false;
        for (let item in newData) {
            if (oldData[newData[item]['id']]) {
                if (JSON.stringify(newData[item]) !== JSON.stringify(oldData[newData[item]['id']])) {
                    oldData[newData[item]['id']] = newData[item];
                    wasChanged = true;
                }
                continue;
            }
            oldData[newData[item]['id']] = newData[item];
            wasChanged = true;
        }

        if (wasChanged) {
            this.setState({
                graphData: oldData
            })  
        }
    }

    preparePondsData() {
        if (!this.props.pondsData) {
            return;
        }        
        
        this.tableHeader = [
            {name: 'date', text: 'Дата', useButton: true},
            {name: 'averageWeigth', text: 'Средний вес, г'}, 
            {name: 'averageLength', text: 'Средняя длина'}, 
            {name: 'averageKu', text: 'Среднее КУ'},
            {name: 'conclusion', text: 'Заключение'}            
        ];

        this.tableColumnStyle = {
            date: 'one', 
            averageWeigth: 'one', 
            averageLength: 'one', 
            averageKu: 'one', 
            conclusion: 'five', 
        }

        if (this.context.allowEdit) {
            this.tableHeader.push({
                name: 'change', 
                text: '', 
                useButton: true, 
                isEdit: true
            });
            this.tableColumnStyle['change'] = 'two'
        }

        const newData = [];
        for (const item in this.props.pondsData['data']) {
            newData.push({title: this.props.pondsData['data'][item]['name']})
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.pondsData)) {
            this.setState({
                pondsData: newData
            })        
        }
    }

    getResearchData() {
        let formData = new FormData();
        formData.append('fisheries_id',this.props.fisheriesData['id']);
        formData.append('pond_id', this.props.pondData['data'][0]['id']);
        let response = sendRequest(formData, '/research/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                    waitXHR: false
                });
                return;
            }                
            this.setState({
                waitXHR: false
            });
            this.calculateTableData(result['table_data']);
            this.calculateGraphData(result['graph_data']);
        });
    }
    
    calculateWaterTableData(data) {
        const newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                date: beautifulDate(data[item]['date']),
                oxygen: data[item]['oxygen'] || 0,
                waterTemperature: data[item]['water_temperature'] || 0,
                airTemperature: data[item]['air_temperature'] || 0,
            }
            if (this.context.allowEdit) {
                element['change'] = {handler: ((e) => {this.createState(e, false, element); this.openWaterCreateWindow()})} || ''
            }
            newData.push(element);
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.waterData)) {
            this.setState({
                waterData: newData
            })        
        }
    }
    
    calculateWaterGraphData(data) {
        const newData = [];
        for (const item in data) {
            if (data[item]['use_date']) {
                for (const item1 in data[item]['data']) {
                    data[item]['data'][item1][data[item]['use_date']] = beautifulDate(data[item]['data'][item1][data[item]['use_date']]);
                }
            }
            newData.push({
                    'id': data[item]['graph_id'],
                    'data': data[item]['data'],
                    'axisX': data[item]['x_name'],
                    'lineOne': data[item]['y0_name'],
                    'lineTwo': data[item]['y1_name'],
                    'graphTitle': data[item]['graph_title'],
                    'lineOneGradientColor': data[item]['graph_id'] === 'graph_weigth_oxygen' && '#ffa993',
                    'lineOneColor': data[item]['graph_id'] === 'graph_weigth_oxygen' && '#db4200'
                }
            )
        }
         let oldData = this.state.graphData;
        let wasChanged = false;
        for (let item in newData) {
            if (oldData[newData[item]['id']]) {
                if (JSON.stringify(newData[item]) !== JSON.stringify(oldData[newData[item]['id']])) {
                    oldData[newData[item]['id']] = newData[item];
                    wasChanged = true;
                }
                continue;
            }
            oldData[newData[item]['id']] = newData[item];
            wasChanged = true;
        }

        if (wasChanged) {
            this.setState({
                graphData: oldData
            })  
        }
    }

    getWaterData() {
        let formData = new FormData();
        formData.append('fisheries_id',this.props.fisheriesData['id']);
        formData.append('pond_id', this.props.pondData['data'][0]['id']);
        let response = sendRequest(formData, '/temperature/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                    waitXHR: false
                });
                return;
            }                
            this.setState({
                waitXHR: false
            });
            this.calculateWaterTableData(result['table_data']);
            this.calculateWaterGraphData(result['graph_data']);
        });
    }

    prepareWaterData() {
        if (!this.props.pondsData) {
            return;
        }        
        
        this.tableWaterHeader = [
            {name: 'date', text: 'Дата', useButton: true},
            // {name: 'averageWeigth', text: 'Средний вес, г'}, 
            // {name: 'averageLength', text: 'Средняя длина'}, 
            // {name: 'averageKu', text: 'Среднее КУ'},
            {name: 'oxygen', text: 'Кислород'},            
            {name: 'waterTemperature', text: 'Температура воды'},            
            {name: 'airTemperature', text: 'Температура воздуха'}    
        ];

        this.tableWaterColumnStyle = {
            date: 'one', 
            // averageWeigth: 'one', 
            // averageLength: 'one', 
            // averageKu: 'one', 
            oxygen: 'two', 
            conclusion: 'two', 
            waterTemperature: 'two', 
            airTemperature: 'two'
        }

        if (this.context.allowEdit) {
            this.tableWaterHeader.push({
                name: 'change', 
                text: '', 
                useButton: true, 
                isEdit: true
            });
            this.tableWaterColumnStyle['change'] = 'two'
        }

        const newData = [];
        for (const item in this.props.pondsData['data']) {
            newData.push({title: this.props.pondsData['data'][item]['name']})
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.pondsData)) {
            this.setState({
                pondsData: newData
            })        
        }
    }

    createCallback() {
        this.getResearchData();
    }

    createState(e, state, value) {
        this.setState({
            isCreate: state,
            initCreateValue: value
        })
    }

    openCreateWindow() {
        this.setState({
            showModal: true
        })
    }

    closeCreateWindow() {
        this.setState({
            showModal: false
        })

    }

    openWaterCreateWindow() {
        this.setState({
            showWaterModal: true
        })
    }

    closeWaterCreateWindow() {
        this.setState({
            showWaterModal: false
        })

    }
    
    resetData() {
        this.setState({
            graphData: {},
            researchData: null,
            waterData: null
        })
    }

    render() {
        if (this.props.activeFisheries === null) {
            return (<div></div>);
        }
        if (!this.props.pondData && !this.props.pondsData && this.props.activeFisheries !== null) {
            return (<LoadingPage />);
        } 
        if (!this.props.pondData && this.props.activeFisheries !== null) {
            return (
                <div className='page-container'> 
                    {this.props.pondsData['data'].map((item, index) => 
                        <CardExtended item={item} id={index} key={index} size='flex' clickable={true} handler={this.props.selectActivePond}/>
                    )}
                </div>)
        } 
        return (                
            <div className='page-container'> 
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeCreateWindow} 
                        content={<CreateWindow 
                                    windowTitle={this.state.isCreate ? 'Добавить новое исследование' : 'Изменить исследование'}
                                    pondName={this.props.pondData['data'][0]['name']}
                                    buttonText={this.state.isCreate ? 'Добавить исследование' : 'Сохранить изменения'}
                                    buttonIcon={this.state.isCreate ? 'button-plus-icon' : undefined}
                                    createCallback={this.createCallback}
                                    isCreate={this.state.isCreate}
                                    saveSuccessText={this.state.isCreate ? 'Исследование добавлено' : 'Исследование изменено'}
                                    fisheriesId={this.props.fisheriesData['id']}
                                    pondId={this.props.pondData['data'][0]['id']}
                                    initValue={this.state.initCreateValue}
                                />} 
                        />
                )}
                {this.state.showWaterModal && (
                    <Modal 
                        onClose={this.closeWaterCreateWindow} 
                        content={<CreateWaterWindow 
                                    windowTitle={this.state.isCreate ? 'Добавить новое исследование' : 'Изменить исследование'}
                                    pondName={this.props.pondData['data'][0]['name']}
                                    buttonText={this.state.isCreate ? 'Добавить исследование' : 'Сохранить изменения'}
                                    buttonIcon={this.state.isCreate ? 'button-plus-icon' : undefined}
                                    createCallback={this.createCallback}
                                    isCreate={this.state.isCreate}
                                    saveSuccessText={this.state.isCreate ? 'Исследование добавлено' : 'Исследование изменено'}
                                    fisheriesId={this.props.fisheriesData['id']}
                                    pondId={this.props.pondData['data'][0]['id']}
                                    initValue={this.state.initCreateValue}
                                />} 
                        />
                )}
                <div className='dashboard-title'>
                    <Select active={this.props.pondData && this.props.pondData['data'][0]['name']} data={this.state.pondsData} handler={this.props.selectActivePond} />
                </div>
                <div className='card-container'>
                    <Card preName='Зарыблено весной' 
                        name={(this.props.pondData ? this.props.pondData['weight'] : 0) + ' кг.'} 
                        subName={(this.props.pondData ? this.props.pondData['count'] : 0) + ' шт.'} 
                        customTitle={true}
                        size='normal' 
                        showBG={false}
                        />
                    <Card preName='Текущий вес' 
                        name={(this.props.pondData ? this.props.pondData['weight_all'] : 0) + ' кг.'} 
                        subName={this.props.pondData ? `${this.props.pondData['weight_status'] === 'positive' ? '+' : this.props.pondData['weight_status'] === 'negative' ? '-' : ''}  ${this.props.pondData['weight_up']} кг.` : '0 кг.'}
                        status={this.props.pondData ? this.props.pondData['weight_status'] : ''}
                        customTitle={true}
                        size='normal' 
                        showBG={false}
                    />   
                    {/* <Card preName='Привес' name={(this.props.pondData ? this.props.pondData['weight_up'] : 0) + ' ц.'} baloonText='' size='flex-big' /> */}
                </div>               
                <div className='block-container'>
                    <div className='dashboard-title-sub'>График пруда</div>
                    <div className='content-container'>
                    {Object.keys(this.state.graphData).length ? Object.keys(this.state.graphData).map((keyName, index) => (
                        <Graph 
                            id={this.state.graphData[keyName]['id']} 
                            data={this.state.graphData[keyName]['data']} 
                            axisX={this.state.graphData[keyName]['axisX']}                                    
                            lineOne={this.state.graphData[keyName]['lineOne']}
                            lineTwo={this.state.graphData[keyName]['lineTwo']}
                            graphTitle={this.state.graphData[keyName]['graphTitle']}
                            lineOneColor={this.state.graphData[keyName]['lineOneColor']}
                            lineOneGradientColor={this.state.graphData[keyName]['lineOneGradientColor']}
                            key={index} 
                        />
                    )): (<div></div>)}
                    </div>
                </div>      
                <div className='block-container'>
                    <div className='dashboard-row'>
                        <div className='dashboard-title-sub'>Исследования длины и веса</div>
                        {this.context.allowEdit && <Button title='Добавить исследование' icon='button-plus-icon' handler={(e) => {this.createState(e, true, null); this.openCreateWindow()}} /> }
                    </div>                  
                    <div className='content-container'>
                        <Table headers={this.tableHeader}  data={this.state.researchData} style={this.tableColumnStyle} bigContainer={true}/>
                    </div>
                </div>   
                <div className='block-container'>
                    <div className='dashboard-row'>
                        <div className='dashboard-title-sub'>Исследования температуры</div>
                        {this.context.allowEdit && <Button title='Добавить исследование' icon='button-plus-icon' handler={(e) => {this.createState(e, true, null); this.openWaterCreateWindow()}} /> }
                    </div>                  
                    <div className='content-container'>
                        <Table headers={this.tableWaterHeader}  data={this.state.waterData} style={this.tableWaterColumnStyle} bigContainer={true}/>
                    </div>
                </div>
            </div>
        );
    }
}
