import React from "react";
import { Link, withRouter } from "react-router-dom";

const Item = withRouter(props => <ItemSource {...props}/>);

class ItemSource extends React.Component {
  render() {
    return (                     
        <li className={`nav-item sidebar-nav-item ${this.props.location.pathname === this.props.path && 'active'} ${this.props.isSubMenu && 'sub-menu'}`}>
            <Link to={this.props.path} className={`${this.props.path === '#' && 'deactivate'} nav-link`}>
                <i className={this.props.icon}></i>
                <span>{this.props.title}</span>
            </Link>
        </li>      
    );
  }
}

export { Item }