import React from "react";

import { Item } from "./Item";

export class Menu extends React.Component {
  render() {
    return (
        <ul className={this.props.class + " nav nav-sidebar-menu sidebar-toggle-view"}>   
           {this.props.items.map((item, i) => <Item path={item.path} icon={item.icon} title={item.title} key={i}/>)}    
           {this.props.subMenu && this.props.subMenu.map((item, i) => <Item path={item.path} icon={item.icon} title={item.title} key={i} isSubMenu={true}/>)}
        </ul>
    );
  }
}
