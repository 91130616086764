import React from "react";

import './Modal.css';


export class Modal extends React.Component {
	constructor(props) {
        super(props);
        this.generateType = this.generateType.bind(this);
    }

    generateType() {
        let type = '';
        if (this.props.status === 'error') {
            type = 'Ошибка!';
        }
        if (this.props.status === 'warning') {
            type = 'Предупреждение';
        }
        if (this.props.status === 'info') {
            type = 'Информация';
        }
        return (
            <h1 className={this.props.status}>
                {type}
            </h1>
        );
    }

    preventBubbling(e) {
        e.stopPropagation();
    }

	render() {
		return (
            <div className="modal-window" onClick={this.props.onClose}>
                <div className='modal-container' onClick={this.preventBubbling}>                    
                    <div className='modal-body'>
                        {this.props.content ? (this.props.content) : (
                            <div>
                                {this.generateType()}
                                <div>{this.props.text}</div>
                            </div>
                        )}
                    </div>
                    <div title="Close" className="modal-close" onClick={this.props.onClose}></div>
                </div>
            </div>
		);
	}
}
