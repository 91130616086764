import React from "react";

import { Input } from "../../Elements/Input/Input";
import { Button } from "../../Elements/Button/Button";

import { sendRequest } from '../../../utils/XHR';

export class FisheriesName extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.renameFisheries = this.renameFisheries.bind(this);    
    }  

    state = {
        errorText: '',
        errorState: false,
        fisheriesName: '',
    }

    componentDidMount() {
        if (this.props.name) {
            this.setState({
                fisheriesName : this.props.name
            })
        }  
    }

    renameFisheries() {       
        let formData = new FormData();
        formData.append('id', this.props.id);
        formData.append('name', this.state.fisheriesName);
        let response = sendRequest(formData, '/fisheries/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }
            this.setState({
                saveSuccess: true
            })
            this.props.onChangeSettings();
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)
        });        
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    render() {
        return (                
            <div className='dashboard-title'>
                <Input title='Название хозяйства' value={this.state.fisheriesName} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'fisheriesName')}/>
                <div className='rename-button'>
                    {(this.state.АsaveSuccess) ? (
                        <div className='succes-block'>
                            <div className='success-icon'></div>
                            <div className='success-text'>Переименовано</div>                           
                        </div>
                    ) : (
                        <Button title='Переименовать' handler={() => this.renameFisheries()} />
                    )}
                    
                </div>   
            </div>
        );
    }
}
