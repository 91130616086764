import React from "react";

import { beautifulDate } from '../../utils/Common';

import { PondBlock } from './PondBlock';
import { Card } from "../Elements/Card/Card";
import { Table } from "../Elements/Table/Table";
import { Select } from "../Elements/Select/Select";


export class FisheriesPage extends React.Component {      
    constructor(props) {
        super(props);
        this.calculateTableData = this.calculateTableData.bind(this);
        this.prepareFisheriesData = this.prepareFisheriesData.bind(this);
        this.tableHeader = [
            {name: 'date', text: 'Дата', useButton: true},
            {name: 'action', text: 'Событие'}, 
            {name: 'responsible', text: 'Отвественный'}, 
            {name: 'weigth', text: 'Вес'}, 
            {name: 'comment', text: 'Комментарий'}
        ];
        this.tableColumnStyle = {
            date: 'one', 
            action: 'two', 
            responsible: 'three', 
            weigth: 'four', 
            comment: 'five'
        }
    }  
    state = {
        feedingData: [],
        fisheriesData: []
    }
    componentDidMount() {
        this.calculateTableData();
        this.prepareFisheriesData();
    }

    componentDidUpdate() {
        this.calculateTableData();
        this.prepareFisheriesData();
    }

    calculateTableData() {
        const newData = [];
        for (const item in this.props.feedingData) {
            newData.push({
                date: beautifulDate(this.props.feedingData[item]['date']),
                action: this.props.feedingData[item]['action'],
                responsible: this.props.feedingData[item]['responsible_short'],
                weigth: this.props.feedingData[item]['feed_weigth'] ? this.props.feedingData[item]['feed_weigth'] + ' кг.' : '',
                comment: this.props.feedingData[item]['comment'] || ''
            })
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.feedingData)) {
            this.setState({
                feedingData: newData
            })        
        }
    }

    prepareFisheriesData() {
        const newData = [];
        for (const item in this.props.allFisheriesData) {
            newData.push({title: this.props.allFisheriesData[item]['name']})
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.fisheriesData)) {
            this.setState({
                fisheriesData: newData
            })        
        }
    }
    render() {
        if (!this.props.fisheriesData) {
            return (<div></div>);
        } 
        return (                
            <div className='page-container'> 
                <div className='dashboard-title'>
                    {this.props.allFisheriesData.length > 1 ?
                        <Select active={this.props.fisheriesData['name']} data={this.state.fisheriesData} handler={this.props.selectActiveFisheries} />
                        : `${this.props.fisheriesData['name']}`
                    }
                </div>
                <div className='card-container'>
                    <Card preName='Зарыблено весной' 
                        name={(this.props.pondsData ? this.props.pondsData['weight'] : 0) + ' кг.'} 
                        subName={(this.props.pondsData ? this.props.pondsData['count'] : 0) + ' шт.'} 
                        customTitle={true}
                        size='normal' 
                        showBG={false}
                        />
                    <Card preName='Текущий вес' 
                        name={(this.props.pondsData ? this.props.pondsData['weight_all'] : 0) + ' кг.'} 
                        subName={this.props.pondsData ? `${this.props.pondsData['weight_status'] === 'positive' ? '+' : this.props.pondsData['weight_status'] === 'negative' ? '-' : ''}  ${this.props.pondsData['weight_up']} кг.` : '0 кг.'}
                        status={this.props.pondsData ? this.props.pondsData['weight_status'] : ''}
                        customTitle={true}
                        size='normal' 
                        showBG={false}
                    />
                    {/* <Card preName='Привес' name={(this.props.pondsData ? this.props.pondsData['weight_up'] : 0) + ' ц.'} baloonText='' size='flex-big' /> */}
                </div>
                <div className='block-container'>
                    <div className='dashboard-title-sub'>События</div>                    
                    <div className='card-container'>
                        <Table headers={this.tableHeader} data={this.state.feedingData} style={this.tableColumnStyle} />
                    </div>
                </div>
                {(this.props.pondTypeData && this.props.pondsData) && this.props.pondTypeData.map((item, index) => 
                    <PondBlock name={item.name} typeId={item.id} key={index} pondsData={this.props.pondsData['data']} selectActivePond={this.props.selectActivePond}/>
                )}    
            </div>
        );
    }
}
