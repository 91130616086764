import React from "react";

import { Button } from "../../Elements/Button/Button";
import { Table } from "../../Elements/Table/Table";
import { Modal } from "../../Elements/Modal/Modal";

import { CreateWindow } from './CreateWindow';
import { EditPondWindow } from "./EditPondWindow";

import { sendRequest } from '../../../utils/XHR';


const pondTableHeader = [
    {name: 'name', text: 'Название'},
    {name: 'sq', text: 'Площадь'},
    {name: 'type', text: 'Тип пруда'},
    {name: 'fish', text: 'Вид рыбы'},
    {name: 'change', text: '',  useButton: true, isEdit: true, isEditText: 'Редактировать' },
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText: 'Удалить' },
];

const pondTableColumnStyle = {
    name: 'one', 
    sq: 'one', 
    type: 'one', 
    fish: 'one', 
    change: 'one', 
    remove: 'one',
};

export class Pond extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);       
       
        this.openCreateWindow = this.openCreateWindow.bind(this);
        this.closeCreateWindow = this.closeCreateWindow.bind(this);
        this.openEditWindow = this.openEditWindow.bind(this);
        this.closeEditWindow = this.closeEditWindow.bind(this);

        this.onCreatePond = this.onCreatePond.bind(this);
                
        this.getAllPonds = this.getAllPonds.bind(this);
        this.calculatePondsTableData = this.calculatePondsTableData.bind(this);
        this.removePond = this.removePond.bind(this);    
  
    }  

    state = {
        errorText: '',
        errorState: false,
        
        saveSuccess: false,

        showOpenModal: false,
        showEditModal: false,
        editValue: [],

        pondsData: [],
        loginValue: '',

        fisheriesDataId: null,
    }

    componentDidMount() {
        this.setState({
            fisheriesDataId: null
        })    
        if (this.props.id) {
            this.getAllPonds();
            this.setState({
                fisheriesDataId: this.props.id
            })    
        }
    }

    componentDidUpdate() {
        if (this.state.fisheriesDataId !== this.props.id) {            
            this.getAllPonds();        
            this.setState({
                fisheriesDataId: this.props.id
            })    
        }
    }
    
    getAllPonds() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/pond/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }       
            for (const index in result['data']) {
                result['data'][index]['fish_count'] = result['data'][index]['fish_count'].toLocaleString();
                result['data'][index]['fish_weight'] = result['data'][index]['fish_weight'].toLocaleString();
                result['data'][index]['sq'] = result['data'][index]['sq'].toLocaleString();
            }     
            this.calculatePondsTableData(result.data);
        });
    }

    calculatePondsTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                name: data[item]['name'],
                sq: data[item]['sq'],
                type: data[item]['pond_type'],
                fish: data[item]['fish_type'],
                type_id: data[item]['type_id'],
                fish_id: data[item]['fish_id'],
            }
            element['change'] = {handler: ((e) => {this.openEditWindow(data[item])})} || ''
            element['remove'] = {handler: ((e) => {this.removePond(data[item]['id'])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.pondsData)) {
            this.setState({
                pondsData: newData
            })  
        }
    }

    removePond(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/pond/delete');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }                
            this.calculatePondsTableData(result.data);
            this.props.onChangeSettings();
        });
    }

    onCreatePond(data) {
        this.calculatePondsTableData(data);
        this.props.onChangeSettings();
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    openCreateWindow() {
        this.setState({
            showOpenModal: true
        })
    }

    closeCreateWindow() {
        this.setState({
            showOpenModal: false
        })

    }  
    
    openEditWindow(value) {
        this.setState({
            showEditModal: true,
            editValue: value
        })
    }

    closeEditWindow() {
        this.setState({
            showEditModal: false,
            editValue: []
        })
    }

    render() {
        return (               
            <div className=''>
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showOpenModal && (
                    <Modal 
                        onClose={this.closeCreateWindow} 
                        content={<CreateWindow 
                                id={this.props.id} 
                                createCallback={(data) => this.onCreatePond(data)}
                            />} 
                        />
                )}
                {this.state.showEditModal && (
                    <Modal 
                        onClose={this.closeEditWindow} 
                        content={<EditPondWindow 
                                id={this.props.id} 
                                initValue={this.state.editValue} 
                                editCallback={(data) => this.onCreatePond(data)}
                            />} 
                        />
                )}
                <div className='dashboard-row'>
                    <div className='dashboard-title-sub'>Настройка прудов</div>
                    <Button title='Добавить новый пруд' icon='button-plus-icon' handler={this.openCreateWindow} />
                </div>    
                <div className='content-container'>
                    <Table 
                        headers={pondTableHeader}
                        data={this.state.pondsData} 
                        style={pondTableColumnStyle} 
                        tableHeight={600} 
                        bigContainer={true}
                    />
                </div>
            </div>
        );
    }
}
