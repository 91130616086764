import React from "react";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";


import { Modal } from '../../Elements/Modal/Modal';
import { FisheriesPage } from "../../FisheriesPage/FisheriesPage";
import { PondPage } from "../../PondPage/PondPage";
import { ResearchPage } from "../../ResearchPage/ResearchPage";
import { SeedingPage } from "../../SeedingPage/SeedingPage";
import { VerticalNavbar } from "../Navbar/VerticalNavbar";
import { LoadingPage } from "../../LoadingPage/LoadingPage";
import { Card } from '../../Elements/Card/Card';

import { Fisheries } from "../../SettingsPage/Fisheries";
import { Ponds } from "../../SettingsPage/Ponds";

import { AdminUserPage } from '../../AdminPage/AdminUserPage';
import { AdminFisheriesPage } from '../../AdminPage/AdminFisheriesPage';

import { sendRequest } from '../../../utils/XHR';

import { APIContext } from '../../../utils/APIContext';

import './Dashboard.css';
import { WaterPage } from "../../WaterPage/WaterPage";


class Dashboard extends React.Component {
    static contextType = APIContext;
    constructor() {
      super();
      this.resetErrorState = this.resetErrorState.bind(this);
      this.resetData = this.resetData.bind(this);
      this.selectActiveFisheries = this.selectActiveFisheries.bind(this);
      this.selectActivePond = this.selectActivePond.bind(this);
    }

    state = {
        errorState: false,
        errorText: '',
        allowEdit: false,
        activeFisheries: null,
        fisheriesData: null,
        pondTypeData: null,
        pondsData: null,
        feedingData: null,
        activePond: null,
        pondData: null,
        needRedirect: false
    };

    componentDidMount() {
        this.getUserData();
    }

    getUserData() {
        let response = sendRequest(new FormData(), '/user/get-info');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }    
            this.context.setMasterRights(result['isMasterAccount']);
            const fisheriesData = result['fisheries_data'];
            if (fisheriesData.length === 1) {
                this.setState({
                    fisheriesData: fisheriesData,
                })
                this.selectActiveFisheries(null, 0);
                this.context.setFisheriesRights(fisheriesData[0]['edit_rights'])
                this.context.setRights(fisheriesData[0]['moderate_rights'])
                // this.resetData();
                return;
            }
            this.setState({
                fisheriesData: fisheriesData
            })
        });
    }

    getPondsData(fisheriesId) {
        let formData = new FormData();
        formData.append('fisheries_id', this.state.fisheriesData[fisheriesId]['id']);
        let response = sendRequest(formData, '/pond/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    pondsData: [],
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            result['count'] = result['count'].toLocaleString();
            result['weight'] = result['weight'].toLocaleString();
            result['weight_up'] = result['weight_up'].toLocaleString();
            result['weight_all'] = result['weight_all'].toLocaleString();
            for (const index in result['data']) {
                result['data'][index]['fish_count'] = result['data'][index]['fish_count'].toLocaleString();
                result['data'][index]['fish_weight'] = result['data'][index]['fish_weight'].toLocaleString();
                result['data'][index]['sq'] = result['data'][index]['sq'].toLocaleString();
                result['data'][index]['full_weigth'] = result['data'][index]['full_weigth'].toLocaleString();
                result['data'][index]['weigth_up'] = result['data'][index]['weigth_up'].toLocaleString();
            }
            this.setState({
                pondsData: result
            })
        });
    }

    getPondTypeData(id) {
        let formData = new FormData();
        formData.append('fisheries_id', this.state.fisheriesData[id]['id']);
        let response = sendRequest(formData, '/pond_type/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({                    
                    pondTypeData: [],
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.setState({
                pondTypeData: result
            })
        });
    }
    
    getFeedingData(id) {
        let formData = new FormData();
        formData.append('fisheries_id', this.state.fisheriesData[id]['id']);
        let response = sendRequest(formData, '/feeding/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({                    
                    feedingData: [],
                    errorText: error,
                    errorState: true
                });
                return;
            }       
            for (const index in result) {
                if (result[index]['feed_weigth']) {
                    result[index]['feed_weigth'] = result[index]['feed_weigth'].toLocaleString();
                }
            }     
            this.setState({
                feedingData: result
            })
        });
    }

    getPondData(fisheriesId, pondId) {
        let formData = new FormData();
        formData.append('fisheries_id', fisheriesId);
        formData.append('id', this.state.pondsData['data'][pondId]['id']);
        let response = sendRequest(formData, '/pond/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    pondData: [],
                    errorText: error,
                    errorState: true
                });
                return;
            }         
            
            result['count'] = result['count'].toLocaleString();
            result['weight'] = result['weight'].toLocaleString();
            result['weight_up'] = result['weight_up'].toLocaleString();
            result['weight_all'] = result['weight_all'].toLocaleString();
            for (const index in result['data']) {
                result['data'][index]['fish_count'] = result['data'][index]['fish_count'].toLocaleString();
                result['data'][index]['fish_weight'] = result['data'][index]['fish_weight'].toLocaleString();
                result['data'][index]['sq'] = result['data'][index]['sq'].toLocaleString();
                result['data'][index]['full_weigth'] = result['data'][index]['full_weigth'].toLocaleString();
                result['data'][index]['weigth_up'] = result['data'][index]['weigth_up'].toLocaleString();
            }    

            this.setState({
                pondData: result,
                activePond: pondId,
                needRedirect: true
            })
        });
    }

    selectActiveFisheries(_e, id) {          
        this.context.setFisheriesRights(this.state.fisheriesData[id]['edit_rights'])
        this.context.setRights(this.state.fisheriesData[id]['moderate_rights'])   
        this.setState({
            activeFisheries: id,
        })
        this.resetData();
        this.getPondTypeData(id);
        this.getPondsData(id);
        this.getFeedingData(id);
    }

    selectActivePond(_e, id) {     
        this.setState({
            needRedirect: false
        })
        this.getPondData(this.state.fisheriesData[this.state.activeFisheries]['id'], id);
    }

    loadActualData() {     
        this.getPondTypeData(this.state.activeFisheries);
        this.getPondsData(this.state.activeFisheries);
        this.getFeedingData(this.state.activeFisheries);
    }
    
    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    resetData() {
        this.setState({
            pondsData: null,
            feedingData: null,
            activePond: null,
            pondData: null,
            needRedirect: false
        })
    }


 	render() {      
        return (   
            <div id="wrapper">              
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <div className="dashboard-page-one">
                    <VerticalNavbar 
                        terminateSession={this.props.terminateSession} 
                        fisheriesData={this.state.fisheriesData} 
                        activeFisheries={this.state.activeFisheries} 
                        selectActiveFisheries={this.selectActiveFisheries}
                    />
                    {this.state.fisheriesData === null ? (<LoadingPage />) : (
                        <div className="dashboard-content-one">
                            {this.state.activeFisheries === null && (
                                <div className='page-container'>
                                    {this.state.fisheriesData.map((item, index) => 
                                        <Card 
                                            name={item.name} 
                                            id={index} 
                                            key={item.id} 
                                            handler={this.selectActiveFisheries} 
                                            size='flex' 
                                            clickable={true}
                                            active={this.state.activeFisheries}
                                        />
                                    )}    
                                </div>
                            )}
                            {this.state.needRedirect && <Redirect to="/lk/ponds" /> }
                            <Switch>
                                {this.context.allowMasterEdit && (              
                                    <Route path='/lk/admin/user'>
                                        <AdminUserPage />
                                    </Route>
                                )}                                
                                {this.context.allowMasterEdit && (              
                                    <Route path='/lk/admin/fisheries'>
                                        <AdminFisheriesPage />
                                    </Route>
                                )}                                                                                          
                                {this.context.allowFisheriesEdit && (              
                                    <Route path='/lk/settings/fisheries'>
                                        <Fisheries 
                                            activeFisheries={this.state.activeFisheries}
                                            fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                            onChangeSettings={() => this.loadActualData()}
                                        />
                                    </Route>
                                )}                                                                                          
                                {this.context.allowFisheriesEdit && (              
                                    <Route path='/lk/settings/ponds'>
                                        <Ponds 
                                            activeFisheries={this.state.activeFisheries}
                                            fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                            onChangeSettings={() => this.loadActualData()}
                                        />
                                    </Route>
                                )}
                                <Route path='/lk/fisheries'>
                                    <FisheriesPage 
                                        activeFisheries={this.state.activeFisheries}
                                        selectActivePond={this.selectActivePond} 
                                        fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                        allFisheriesData={this.state.fisheriesData}
                                        selectActiveFisheries={this.selectActiveFisheries} 
                                        pondTypeData={this.state.pondTypeData}
                                        pondsData={this.state.pondsData}
                                        feedingData={this.state.feedingData}
                                    />
                                </Route>  
                                <Route path='/lk/ponds'>
                                    <PondPage                               
                                        selectActivePond={this.selectActivePond} 
                                        activeFisheries={this.state.activeFisheries}
                                        fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                        pondTypeData={this.state.pondTypeData}
                                        pondsData={this.state.pondsData}
                                        pondData={this.state.pondData}
                                        feedingData={this.state.feedingData}
                                    />
                                </Route>                                
                                <Route path='/lk/research/water'>
                                    <WaterPage                             
                                        selectActivePond={this.selectActivePond} 
                                        activeFisheries={this.state.activeFisheries}
                                        fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                        pondTypeData={this.state.pondTypeData}
                                        pondsData={this.state.pondsData}
                                        pondData={this.state.pondData}
                                    />                            
                                </Route>
                                <Route path='/lk/research'>
                                    <ResearchPage                             
                                        selectActivePond={this.selectActivePond} 
                                        activeFisheries={this.state.activeFisheries}
                                        fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                        pondTypeData={this.state.pondTypeData}
                                        pondsData={this.state.pondsData}
                                        pondData={this.state.pondData}
                                    />                            
                                </Route>
                                <Route path='/lk/seeding'>
                                    <SeedingPage                               
                                        selectActivePond={this.selectActivePond} 
                                        activeFisheries={this.state.activeFisheries}
                                        fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                        onChangeSettings={() => this.loadActualData()}
                                    />  
                                </Route>
                                {/* <Route path='/lk/catch'>
                                    <CatchPage                               
                                        selectActivePond={this.selectActivePond} 
                                        activeFisheries={this.state.activeFisheries}
                                        fisheriesData={this.state.fisheriesData[this.state.activeFisheries]}
                                        pondTypeData={this.state.pondTypeData}
                                        pondsData={this.state.pondsData}
                                        pondData={this.state.pondData}
                                    />  
                                </Route> */}
                                <Route render={() => this.state.activeFisheries !== null && <Redirect to="/lk/fisheries" />} />
                            </Switch>
                        </div>
                    )}
                </div>
            </div>           
        );
    }
}

export default withRouter(Dashboard);