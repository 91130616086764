import React from "react";

import { Input } from "../../Input/Input";


export class WeigthLengthRow extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);     
  
    }  

    state = {
        weigth: '',
        length: '',
        fullness: '',
        ku: '',
    }

    componentDidMount() {
        if (this.props.initValue) {
            this.setState({
                weigth: this.props.initValue.weigth,
                length: this.props.initValue.length,
                fullness: this.props.initValue.fullness,
                ku: this.props.initValue.ku
            })
        }
    }   

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
        this.props.changeCallback &&  this.props.changeCallback(this.props.serial-1, stateName, e.target.value);
    }

    render() {
        return (               
            <div className='row-modal'>
                <div className="weigth-length-table-one serial">
                    {this.props.serial}
                </div>
                <div className="weigth-length-table-two ">
                    <Input title='' value={this.state.weigth} changeHandler={(e) => this.changeHandler(e, 'weigth')} />
                </div>
                <div className="weigth-length-table-two ">
                    <Input title='' value={this.state.length} changeHandler={(e) => this.changeHandler(e, 'length')} />
                </div>
                {/* <div className="weigth-length-table-two ">
                    <Input title='' value={this.state.fullness} changeHandler={(e) => this.changeHandler(e, 'fullness')} />
                </div> */}
                <div className="weigth-length-table-two ">
                    <Input title='' value={this.state.ku} changeHandler={(e) => this.changeHandler(e, 'ku')} />
                </div>
            </div>  
        );
    }
}
