import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { AuthPage } from "../AuthPage/AuthPage";
import { IndexPage } from "../IndexPage/IndexPage";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { default as Dashboard } from "./Dashboard/Dashboard";

import { sendRequest } from "../../utils/XHR";
import { getCookie, removeCookie } from "../../utils/Cookie";
import { APIContextProvider } from '../../utils/APIContext';

import "./MasterPage.css";

export class MasterPage extends React.Component {
  constructor() {
    super();
    this.setAuthState = this.setAuthState.bind(this);
    this.checkAlive = this.checkAlive.bind(this);
    this.terminateSession = this.terminateSession.bind(this);
  }

  state = {
    isAuth: null
  };

  componentDidMount() {
    if (getCookie('api-token')){
      this.checkAlive();
    } else {      
      this.setState({
        isAuth: false
      });
    }   
  }

  checkAlive() {
    let response = sendRequest(new FormData(), '/user/alive');
      response.then((result) => {
        const error = result.error;  
        if (error) {
          this.setState({
            isAuth: false
          });
          return;
        }            
        this.setState({
          isAuth: true
        });
    });
  }

  sidebarCollapsedState() {
    this.setState({
      sidebarCollapsed: !this.state.sidebarCollapsed,
    });
  }

  sidebarCollapsedMobileState() {
    this.setState({
      sidebarCollapsedMobile: !this.state.sidebarCollapsedMobile,
    });
  }

  sidebarStatus() {
    if (this.state.sidebarCollapsed) {
      return "wrapper bg-ash sidebar-collapsed";
    }
    if (this.state.sidebarCollapsedMobile) {
      return "wrapper bg-ash sidebar-collapsed-mobile";
    }
    return "wrapper bg-ash";
  }

  setAuthState(isAuth, response = {}) {
    this.setState({
      isAuth: isAuth,
    });
  }

  terminateSession() {    
    removeCookie('api-token');
    this.checkAlive();
  }

  render() {
    if (this.state.isAuth === null) {
      return (
        <LoadingPage full={true}/>
      )
    } else {
      return (
        <APIContextProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/auth">
                {this.state.isAuth ? <Redirect to="/lk" /> : <AuthPage setAuth={this.setAuthState} />}
              </Route>
              <Route path="/lk">
                {this.state.isAuth ? <Dashboard terminateSession={this.terminateSession}/> : <Redirect to="/auth" /> }
              </Route>
              <Route path="/">
                <IndexPage />
              </Route>
            </Switch>
          </BrowserRouter>
        </APIContextProvider>
      );
    }
  }    
}
