import React from 'react';

const APIContext = React.createContext();
//const APIContextConsumer = APIContext.Consumer

class APIContextProvider extends React.Component {    
    state = {
        allowEdit: false,        
        allowFisheriesEdit: false,
        allowMasterEdit: false,
        pondsData: null,
        researchData: null
    }

    setRights = (right) => {
       this.setState({
           allowEdit: right || false
       })
    }

    setFisheriesRights = (right) => {
       this.setState({
            allowFisheriesEdit: right || false
       })
    }     

    setMasterRights = (right) => {
       this.setState({
           allowMasterEdit: right || false
       })
    }
     
    setData = (name, value) => {
        this.setState({
            [name]: value
        })
    }


    render() {
        return <APIContext.Provider value={{
            'allowEdit': this.state.allowEdit, 
            'setRights': this.setRights,
            'allowFisheriesEdit': this.state.allowFisheriesEdit, 
            'setFisheriesRights': this.setFisheriesRights,
            'allowMasterEdit': this.state.allowMasterEdit, 
            'setMasterRights': this.setMasterRights,
            'setData': this.setData, 
            'pondsData': this.state.pondsData, 
            'researchData': this.state.researchData, 
        }}>
            {this.props.children}
        </APIContext.Provider>;
    }
}

export { APIContext, APIContextProvider }