import React from "react";

import { Input } from "../../Elements/Input/Input";
import { Button } from "../../Elements/Button/Button";
import { Table } from "../../Elements/Table/Table";
import { Modal } from "../../Elements/Modal/Modal";

import { sendRequest } from '../../../utils/XHR';

const roleList = [
    {title : 'Администартор', level: 3},
    {title :'Модератор', level: 2},
    {title :'Пользователь', level: 1}
];

const userTableHeader = [
    {name: 'login', text: 'Пользователь'},
    {name: 'role', text: 'Роль', useSelect: true, selectData: roleList},    
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Удалить' },
];

const userTableColumnStyle = {
    login: 'one', 
    role: 'one', 
    remove: 'one', 
};

export class Users extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        
        this.getAllUsers = this.getAllUsers.bind(this);
        this.calculateUsersTableData = this.calculateUsersTableData.bind(this);
        this.addUser = this.addUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.setUserRigths = this.setUserRigths.bind(this);
          
    }  

    state = {
        errorText: '',
        errorState: false,

        usersData: [],
        loginValue: '',
    }

    componentDidMount() {
        if (this.props.id) {
            this.getAllUsers();
        }
    }

    getAllUsers() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/settings/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.calculateUsersTableData(result);
        });
    }

    calculateUsersTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                userId: data[item]['user_id'],
                fisheriesId: data[item]['fisheries_id'],
                login: data[item]['user_name'],
            }
            element['role'] = {name: data[item]['user_level'], selectHandler: ((_e, index) => {this.setUserRigths(data[item]['user_id'], roleList[index].level)})};
            element['remove'] = {handler: ((e) => {this.removeUser(data[item]['id'])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.usersData)) {
            this.setState({
                usersData: newData
            })  
        }
    }

    addUser() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('login', this.state.loginValue);
        let response = sendRequest(formData, '/settings/add-user');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.props.onChangeSettings();
            this.calculateUsersTableData(result);
        });
    }

    removeUser(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/settings/delete');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }                
            this.props.onChangeSettings();
            this.calculateUsersTableData(result);
        });
    }

    setUserRigths(userId, userLevel) {
        let formData = new FormData();
        formData.append('user_id', userId);        
        formData.append('user_level', userLevel);              
        formData.append('fisheries_id', this.props.id);      
        let response = sendRequest(formData, '/settings/update-by/user');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }       
            this.props.onChangeSettings();         
            this.calculateUsersTableData(result);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    render() {
        return (   
            <div className='block-container'>
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <div className='dashboard-row'>
                    <div className='dashboard-title-sub'>Список пользователей</div>
                </div>    
                <div className='content-container'>
                    <Table 
                        headers={userTableHeader}
                        data={this.state.usersData} 
                        style={userTableColumnStyle} 
                        tableHeight={300} 
                    />
                </div>
                <div className='fast-add-container'>
                    <Input title='Логин' value={this.state.loginValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'loginValue')} />
                    <Button title='Добавить пользователя' icon='button-plus-icon' handler={() => this.addUser()} />
                </div>
            </div>        
        );
    }
}
