import React from "react";

import { Input } from '../Input/Input';
import { Select } from '../Select/Select';

import './Filter.css';

export class Filter extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.selectorChange = this.selectorChange.bind(this);
    }  


    state = {
        dateFrom: '',
        dateTo: '',
    }

    changeHandler(e, stateName) {
        this.props.action && this.props.action(e, stateName, e.target.value);
        this.setState({
            [stateName]: e.target.value
        });
    }

    selectorChange(e, id) { 
        this.props.selectorChange && this.props.selectorChange(e, this.props.selectorName, id);
    }

    render() {
        return (   
           <div className='filter-container'>
               {this.props.useSelector && (
                <div className='selector-wrapper input-container'>
                        <div className='input-title'>
                            Выберите пруд
                        </div> 
                        <div className='input-content'>
                            <Select active={this.props.selectorCurrentTitle} data={this.props.selectorData} handler={this.selectorChange} />
                        </div>
                </div>
               )}
               <div>
                    <Input title='От' type='date' value={this.state.dateFrom} changeHandler={(e) => this.changeHandler(e, 'dateFrom')}/>      
               </div>
               <div>
                    <Input title='До' type='date' value={this.state.dateTo} changeHandler={(e) => this.changeHandler(e, 'dateTo')}/> 
               </div>
           </div>
        );
    }
}
