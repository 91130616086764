import React from 'react'
import { AreaChart, ResponsiveContainer, Area, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';


import { LoadingPage } from "../../LoadingPage/LoadingPage";


export class Graph extends React.Component {        
    render() {
        if (this.props.data === null) {
            return (<LoadingPage />);
        }
        if (!this.props.data) {
            return (<div>Данных для построения графика не достаточно</div>);
        }
        return (
            <div className='card-block card-flex'> 
                <div className='pre-title'>
                    {this.props.graphTitle}
                </div>
                <ResponsiveContainer width="100%" height={440}>
                    <AreaChart data={this.props.data}  margin={{ top: 40, right: 40, left: 40, bottom: 40 }}>
                        <defs>
                            <linearGradient id={`${this.props.id}coloOne`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={`${this.props.lineOneGradientColor || '#93ffe2'}`} stopOpacity={0.8}/>
                                <stop offset="95%" stopColor={`${this.props.lineOneGradientColor || '#93ffe2'}`} stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id={`${this.props.id}colorTwo`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={`${this.props.lineTwoGradientColor || '#EEF1FF'}`} stopOpacity={0.8}/>
                                <stop offset="95%" stopColor={`${this.props.lineTwoGradientColor || '#EEF1FF'}`} stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey={this.props.axisX} tickLine={true} axisLine={true} />    
                        <YAxis 
                            dataKey={this.props.lineOne} 
                            yAxisId="1" 
                            tickCount={10} 
                            tickLine={true} 
                            axisLine={true} 
                            minTickGap={2} 
                        /> 
                        <YAxis 
                            dataKey={this.props.lineTwo} 
                            yAxisId="2" 
                            orientation='right' 
                            tickCount={10} 
                            tickLine={true} 
                            axisLine={true} 
                            minTickGap={2} 
                        />
                        <CartesianGrid stroke="#E7E7E8" />
                        <Tooltip />
                        <Area yAxisId={1}  type="monotone" dataKey={this.props.lineOne} stroke={`${this.props.lineOneColor || '#62d7a5'}`} fillOpacity={1} fill={`url(#${this.props.id}coloOne)`} />
                        <Area yAxisId={2} type="monotone" dataKey={this.props.lineTwo} stroke={`${this.props.lineTwoColor || '#4D69FF'}`} fillOpacity={1} fill={`url(#${this.props.id}colorTwo)`} />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
