import { Card } from "./Card";

export class CardExtended extends Card {
    cardHandler(e) {
        if (this.props.handler) {
            this.props.handler(e, this.props.id)
        }
    }

    render() {
        return (
            <div className={this.blockSize[this.state.size] + ' ' + this.state.clickable + ' card-block card-extended'} onClick={this.cardHandler}>
                <div className="card-body">
                    <div className="card-row header">
                        <div className="title">{this.props.item['name'] || 'Без имени'}</div>  
                        <div className="baloon">{this.props.item['research_data']['temp'] || 0} <sup>o</sup></div>  
                    </div>
                    
                    <div className="card-column">
                        <div className="card-row">
                            <div className="pre-title">Зарыблено весной</div>    
                        </div>
                        <div className="card-row">
                            <div className="sub-title">{this.props.item['fish_weight'] || 0} кг.</div>    
                        </div>
                        <div className="card-row">
                            <div className="sub-title">{this.props.item['fish_count'] || 0} шт.</div>    
                        </div>
                    </div>
                </div>
                <div className={parseInt(this.props.item['research_data']['weigth']) >= 0 ? "card-footer" : "card-footer negative"}>
                    <div className="card-row">
                        <div className="pre-title">Текущий вес</div>    
                    </div>
                    <div className="card-row">
                        <div className="sub-title">{this.props.item['full_weigth']} кг.</div>    
                        <div className="baloon">{parseInt(this.props.item['weigth_up']) >= 0 && "+" } {this.props.item['weigth_up'] || 0}</div>  
                    </div>
                   
                </div>  
            </div>
        );
    }
}
