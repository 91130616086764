import React from "react";

import { Button } from "../Elements/Button/Button";
import { Filter } from "../Elements/Filter/Filter";
import { Table } from "../Elements/Table/Table";
import { Modal } from "../Elements/Modal/Modal";
import { CreateWaterWindow } from "../Elements/Extended/CreateResearch/CreateWaterWindow";
import { LoadingPage } from "../LoadingPage/LoadingPage";


import { sendRequest } from '../../utils/XHR';
import { beautifulDate } from '../../utils/Common';
import { APIContext } from '../../utils/APIContext';
import { Numerator } from "../Elements/Numerator/Numerator";


export class WaterPage extends React.Component {  
    static contextType = APIContext;

    constructor(props) {
        super(props);
        this.tableHeader = [];
        this.openCreateWindow = this.openCreateWindow.bind(this);
        this.closeCreateWindow = this.closeCreateWindow.bind(this);
        this.createState = this.createState.bind(this);
        this.getResearchData = this.getResearchData.bind(this);
        this.resetData = this.resetData.bind(this);
        this.changePond = this.changePond.bind(this);
        this.changeTablePage = this.changeTablePage.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.initPondsData = this.initPondsData.bind(this);
        this.selectPond = this.selectPond.bind(this);
    }  

    state = {
        isCreate: true,
        initCreateValue: null,
        waitXHR: false,
        showModal: false,
        researchData: null,
        activeFisheries: null,
        pondsData: null,
        pondId: -1,
        pondName: '',
        numeratorCount: 0,
        currentPage: 0,
        filter: {}
    }

    componentDidMount() {
        if (this.props.activeFisheries !== this.state.activeFisheries) {
            this.prepareResearchData();
            this.getResearchData();  
        }
    }

    componentDidUpdate() {     
        if (this.props.pondsData && !this.state.pondsData) {
            this.initPondsData();
        }
        if (this.props.activeFisheries !== this.state.activeFisheries) {
            this.setState({
                activeFisheries: this.props.activeFisheries
            });
            this.prepareResearchData();
            this.getResearchData();
        }
    }

    prepareResearchData() {
        this.tableHeader = [
            {name: 'date', text: 'Дата', useButton: true},
            {name: 'pondName', text: 'Пруд'}, 
            {name: 'oxygen', text: 'Кислород'},           
            {name: 'waterTemperature', text: 'Температура воды'},            
            {name: 'airTemperature', text: 'Температура воздуха'}
        ];

        this.tableColumnStyle = {
            date: 'one', 
            pondName: 'one', 
            oxygen: 'two', 
            waterTemperature: 'two', 
            airTemperature: 'two'
        }

        if (this.context.allowEdit) {
            this.tableHeader.push({
                name: 'change', 
                text: '', 
                useButton: true, 
                isEdit: true
            });
            this.tableColumnStyle['change'] = 'two'
        }
        
        this.tableHeader.push({
            name: 'print', 
            text: '', 
            useButton: true, 
            isEdit: true,
            isEditIcon: 'false',
            isEditText: 'Печать'
        });
        this.tableColumnStyle['print'] = 'one'
    }

    getResearchData(offset = 0, filter = {}) {
        if (!Object.keys(filter).length) {
            filter = this.state.filter;
        }
        let formData = new FormData();
        formData.append('fisheries_id',this.props.fisheriesData['id']);
        formData.append('offset', offset);
        for (var key in filter) {
            if (key === 'dateFrom') {
                const date = new Date(filter[key]).toISOString().slice(0, 11) + new Date().toISOString().slice(11)
                formData.append('date_start', date);
            }
            if (key === 'dateTo') {
                const date = new Date(filter[key]).toISOString().slice(0, 11) + new Date().toISOString().slice(11)
                formData.append('date_end', date);
            }
            if (key === 'pondId') {
                formData.append('pond_id', this.props.pondsData['data'][filter[key]]['id']);
            }
        }
        let response = sendRequest(formData, '/temperature/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                    waitXHR: false
                });
                return;
            }                
            this.setState({
                waitXHR: false
            });
            this.calculateTableData(result['table_data']);
            this.setState({
                numeratorCount: result['numerator']
            })
        });
    }

    calculateTableData(data) {
        const newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                date: beautifulDate(data[item]['date']),
                oxygen: data[item]['oxygen'] || 0,
                waterTemperature: data[item]['water_temperature'] || 0,
                airTemperature: data[item]['air_temperature'] || 0,
                pondName: data[item]['pond_name'] || '',
                pondId: data[item]['pond_id'] || '',
            }
            if (this.context.allowEdit) {
                element['change'] = {handler: ((e) => {this.createState(e, false, element); this.openCreateWindow()})} || ''
            }
            newData.push(element);
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.researchData)) {
            this.setState({
                researchData: newData
            })        
        }
    }

    initPondsData(value) {
        var newData = [];
        for (const item in this.props.pondsData['data']) {
            newData.push({
                title: this.props.pondsData['data'][item]['name'],
                id: this.props.pondsData['data'][item]['id']
            })
        }
        this.setState({ 
            pondsData: newData,
            pondId: value ? value['pondId'] : newData[0]['id'],
            pondName: value ? value['pondName'] : newData[0]['title']
        })
    }

    createState(e, state, value) {
        this.initPondsData(value);
        this.setState({
            isCreate: state,
            initCreateValue: value
        })
    }
    
    changePond(_e, id) {
        this.setState({
            pondId: this.state.pondsData[id]['id'],
            pondName: this.state.pondsData[id]['title']
        })
    }

    selectPond(e, name, id) {
        this.changePond(e, id);
        this.applyFilter(e, name, id);
    }

    changeTablePage(e, index) {
        if (typeof index === 'undefined') {
            return;
        }
        this.setState({
            currentPage: index
        })
        this.getResearchData(index*10)
    }

    applyFilter(e, propName, value) {
        var currentFilter = {...this.state.filter};
        currentFilter[propName] = value
        this.setState({
            filter: currentFilter
        });
        this.getResearchData(0, currentFilter);
    }

    resetData() {
        this.setState({
            graphData: {},
            researchData: null,
            waterData: null
        })
    }

    openCreateWindow() {
        this.setState({
            showModal: true
        })
    }

    closeCreateWindow() {
        this.setState({
            showModal: false
        })

    }

    render() {
        if (this.props.activeFisheries === null) {
            return (<div></div>);
        }
        if (!this.props.pondData && !this.props.pondsData && this.props.activeFisheries !== null) {
            return (<LoadingPage />);
        } 
        return (                
            <div className='page-container'> 
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeCreateWindow} 
                        content={<CreateWaterWindow 
                                    windowTitle={this.state.isCreate ? 'Добавить новое исследование' : 'Изменить исследование'}
                                    buttonText={this.state.isCreate ? 'Добавить исследование' : 'Сохранить изменения'}
                                    buttonIcon={this.state.isCreate ? 'button-plus-icon' : undefined}
                                    createCallback={this.createCallback}
                                    isCreate={this.state.isCreate}
                                    saveSuccessText={this.state.isCreate ? 'Исследование добавлено' : 'Исследование изменено'}
                                    fisheriesId={this.props.fisheriesData['id']}
                                    pondId={this.state.pondId}
                                    pondName={this.state.pondName}
                                    initValue={this.state.initCreateValue}
                                    useSelectPond={true}
                                    pondsData={this.state.pondsData}
                                    changePond={this.changePond}
                                />} 
                        />
                )}
                <div className='dashboard-row start'>
                    <div className='dashboard-title'>Анализ воды</div>
                    <Filter 
                        useSelector={true}
                        selectorName='pondId'
                        selectorCurrentId={this.state.pondId}
                        selectorCurrentTitle={this.state.pondName}
                        selectorData={this.state.pondsData}
                        selectorChange={this.selectPond}
                        action={this.applyFilter} 
                    />
                    {this.context.allowEdit && <Button title='Добавить исследование' icon='button-plus-icon' handler={(e) => {this.createState(e, true, null); this.openCreateWindow()}} /> }   
                </div>   
                <div className='content-container'>
                    <Table headers={this.tableHeader}  data={this.state.researchData} style={this.tableColumnStyle} bigContainer={true} tableHeight='500' />
                </div>
                <div>
                    <Numerator count={this.state.numeratorCount} current={this.state.currentPage} action={this.changeTablePage}/>
                </div>
               
            </div>
        );
    }
}
