import Config  from './Config';

/* Вспомогательные методы
*/

/* Метод возвращает время жизни сессии
*/
export function createTimestamp() {
    return Date.now() + Config.coockieTime;
}

/* Метод для копирования объекта (при пуше в массив передается по ссылке, что переписывает весь массив)
*/
export function cloneObject(object) {
    var clone = {};
    for (let key in object) {
        if(object.hasOwnProperty(key))
            clone[key] = object[key];
    }
    return clone;
}

 /* Метод возвращает красивую дату
    */
 export function beautifulDate(date) {
    if (!date) { return ''; }
    let regex = new RegExp(/(^\d+)-(\d+)-(\d+)/);
    let match = date.match(regex);
    if (!match) {
        return date;
    }
    if (match[3] === "00") {
        return `${match[2]}.${match[1]}`;
    }
    return `${match[3]}.${match[2]}.${match[1]}`;
}

export function strictDate(date) {
    if (!date) { return ''; }
    let regex = new RegExp(/(^\d+).(\d+).(\d+)/);
    let match = date.match(regex);
    return match ? `${match[3]}-${match[2]}-${match[1]}` : date;
}

export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }