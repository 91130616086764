import React from "react";

import { Input } from "../../Elements/Input/Input";
import { Button } from "../../Elements/Button/Button";
import { Table } from "../../Elements/Table/Table";
import { Modal } from "../../Elements/Modal/Modal";

import { EditWindow } from "./EditWindow";

import { sendRequest } from '../../../utils/XHR';

const responsibleTableHeader = [
    {name: 'surname', text: 'Фамилия'},
    {name: 'name', text: 'Имя'},    
    {name: 'middlename', text: 'Отчество'},    
    {name: 'change', text: '',  useButton: true, isEdit: true, isEditText: 'Редактировать' },
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Удалить' },
];

const responsibleTableColumnStyle = {
    surname: 'one', 
    name: 'one', 
    middlename: 'one', 
    change: 'one', 
    remove: 'one', 
}


export class Responsible extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.openEditWindow = this.openEditWindow.bind(this);
        this.closeEditWindow = this.closeEditWindow.bind(this);
        
        this.getResponsibleData = this.getResponsibleData.bind(this);
        this.calculateResponsibleTableData = this.calculateResponsibleTableData.bind(this);
        this.addResponsible = this.addResponsible.bind(this);
        this.removeResponsible = this.removeResponsible.bind(this);       
        this.updateResponsible = this.updateResponsible.bind(this);       
  
    }  

    state = {
        errorText: '',
        errorState: false,
        
        saveSuccess: false,
        
        showModal: false,
        editValue: null,

        responsibleData: [],
        surnameValue: '',
        nameValue: '',
        middlenameValue: '',
    }

    componentDidMount() {
        if (this.props.id) {
            this.getResponsibleData();
        }
    }

    getResponsibleData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/responsible/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.calculateResponsibleTableData(result);
        });
    }

    calculateResponsibleTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                surname: data[item]['surname'],
                name: data[item]['name'],
                middlename: data[item]['middlename'],
            }
            const forChange = {
                data: [
                    {
                        title: 'Фамилия',
                        value: data[item]['surname'],
                        valueName: 'surnameValue'
                    },
                    {
                        title: 'Имя',
                        value: data[item]['name'],
                        valueName: 'nameValue'
                    },
                    {
                        title: 'Отчество',
                        value: data[item]['middlename'],
                        valueName: 'middlenameValue'
                    }
                ],
                id: data[item]['id']
            }
            element['change'] = {handler: ((e) => {this.openEditWindow(forChange)})} || ''
            element['remove'] = {handler: ((e) => {this.removeResponsible(data[item]['id'])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.responsibleData)) {
            this.setState({
                responsibleData: newData
            })  
        }
    }

    addResponsible() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', this.state.nameValue);
        formData.append('surname', this.state.surnameValue);
        formData.append('middlename', this.state.middlenameValue);
        let response = sendRequest(formData, '/responsible/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }         
            this.props.onChangeSettings();   
            this.calculateResponsibleTableData(result);
        });
    }

    removeResponsible(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/responsible/delete');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }  
            this.props.onChangeSettings();              
            this.calculateResponsibleTableData(result);
        });
    }

    updateResponsible(args) {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', args.nameValue);
        formData.append('surname', args.surnameValue);
        formData.append('middlename', args.middlenameValue);
        formData.append('id', args.id);
        let response = sendRequest(formData, '/responsible/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }         
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)  
            this.props.onChangeSettings(); 
            this.calculateResponsibleTableData(result);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false            
        });
    }

    openEditWindow(value) {
        this.setState({
            showModal: true,
            editValue: value
        })
    }

    closeEditWindow() {
        this.setState({
            showModal: false,
            editValue: null
        })

    }
    

    render() {
        return (   
            <div className='block-container'>
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeEditWindow} 
                        content={<EditWindow 
                                    title='Редактирование ответственного'
                                    initValue={this.state.editValue}
                                    updateCallback={(args) => this.updateResponsible(args)}
                                />} 
                        />
                )}
                <div className='dashboard-title-sub'>Ответственные</div>                    
                <div className='card-container'>                        
                    <Table 
                        headers={responsibleTableHeader}
                        data={this.state.responsibleData} 
                        style={responsibleTableColumnStyle} 
                        tableHeight={300} 
                    />
                </div>
                <div className='fast-add-container'>
                    <Input title='Фамилия' value={this.state.surnameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'surnameValue')} />
                    <Input title='Имя' value={this.state.nameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'nameValue')} />
                    <Input title='Отчество' value={this.state.middlenameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'middlenameValue')} />
                    {(this.state.saveSuccess) ? (
                        <div className='succes-block'>
                            <div className='success-icon'></div>
                            <div className='success-text'>Ответственный добавлен</div>                           
                        </div>
                    ) : (
                        <Button title='Добавить ответственного' icon='button-plus-icon'  handler={() => this.addResponsible()} />
                    )}                    
                </div>
            </div>
        );
    }
}
