import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import { Input } from '../../Input/Input';
import { Select } from '../../Select/Select';

import { strictDate } from '../../../../utils/Common';
import { sendRequest } from '../../../../utils/XHR';
import { WeigthLengthRow } from './WeigthLengthRow';

import './CreateWindow.css';

export class CreateWindow extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.sendReq = this.sendReq.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.addMoreRow = this.addMoreRow.bind(this);
        this.changeWeigthLength = this.changeWeigthLength.bind(this);
    }  

    state = {
        saveSuccess: false,
        errorState: false,
        errorText: '',
        dateValue: '',
        cliniсalValue: '',
        gillsValue: '',
        surfaceValue: '',
        fullnessValue: '',
        feedRatioValue: '',
        pathogenesisValue: '',
        conclusionValue: '',
        researchId: '',
        weigthLengthData: []
    }

    componentDidMount() {
        if (this.props.initValue){
            this.setState({               
                dateValue: strictDate(this.props.initValue['date']),
                cliniсalValue: this.props.initValue['clinical'],
                gillsValue: this.props.initValue['gills'],
                surfaceValue: this.props.initValue['surface'],
                fullnessValue: this.props.initValue['fullness'],
                feedRatioValue: this.props.initValue['feed_ratio'],
                pathogenesisValue: this.props.initValue['pathogenesis'],
                conclusionValue: this.props.initValue['conclusion'],
                researchId: this.props.initValue['id'],
                weigthLengthData: this.props.initValue['weigthLengthData'],
            })
        }
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    prepareDataForRequest() {
        const date = this.state.dateValue ? new Date(this.state.dateValue).toISOString().slice(0, 11) + new Date().toISOString().slice(11) : new Date().toISOString();
        let formData = new FormData();
        formData.append('fisheries_id', this.props.fisheriesId || 0);
        formData.append('pond_id', this.props.pondId || 0);
        formData.append('fish_age', this.state.ageValue || 0);
        formData.append('date', date);
        formData.append('clinical', this.state.cliniсalValue || '');
        formData.append('gills', this.state.gillsValue || '');
        formData.append('surface', this.state.surfaceValue || '');
        formData.append('pathogenesis', this.state.pathogenesisValue || '');
        formData.append('conclusion', this.state.conclusionValue || '');
        formData.append('fullness', this.state.fullnessValue || 0);
        formData.append('feed_ratio', this.state.feedRatioValue || '');
        formData.append('weigth_length_data', JSON.stringify(this.state.weigthLengthData) || '');
        
        return formData;
    }    

    sendReq(method) {       
        let formData = this.prepareDataForRequest();
        if (method ==='update') {
            formData.append('id', this.state.researchId);
        }
        let response = sendRequest(formData, '/research/' + method);
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }
            this.setState({
                saveSuccess: true
            })
            this.props.createCallback && this.props.createCallback();
        });        
    }

    addMoreRow() {
        const weigthLengthData = this.state.weigthLengthData;
        weigthLengthData.push({
            serial: weigthLengthData.length+1,
            weigth: 0,
            length: 0,
            fullness: 0,
            ku: 0,
        });
        this.setState({
            weigthLengthData: weigthLengthData
        })
    }

    changeWeigthLength(index, row, value) {
        const weigthLengthData = this.state.weigthLengthData;
        weigthLengthData[index][row] = value;
        this.setState({
            weigthLengthData: weigthLengthData
        })
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }


	render() {
		return (
           <div className='research-modal'>               
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <div className='title-modal'>
                        {this.props.windowTitle}
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>
                    <div className='row-modal'>
                        <div className='input-column'>
                            {this.props.useSelectPond && (
                                <div className='row-modal'>                                    
                                    <div className='sub-title-modal'>
                                        <Select active={this.props.pondName} data={this.props.pondsData} handler={this.props.changePond} />
                                    </div>   
                                </div>   
                            )}
                            <div className='row-modal'>
                                <Input title='Дата исследования' type='date' value={this.state.dateValue} changeHandler={(e) => this.changeHandler(e, 'dateValue')}/> 
                            </div>   
                            <div className='row-modal'>
                                <div className='sub-title-modal'>
                                    Микроскопические исследования
                                </div>
                            </div>   
                            <div className='row-modal'>
                                <Input title='Клинические признаки' value={this.state.cliniсalValue} changeHandler={(e) => this.changeHandler(e, 'cliniсalValue')} fullWidth={true} />                                
                            </div>   
                            <div className='row-modal'>
                                <Input title='Жабры' value={this.state.gillsValue} changeHandler={(e) => this.changeHandler(e, 'gillsValue')} fullWidth={true} />
                            </div>   
                            <div className='row-modal'>
                                <Input title='Поверхность' value={this.state.surfaceValue} changeHandler={(e) => this.changeHandler(e, 'surfaceValue')} fullWidth={true}/>                                
                            </div>          
                            <div className='row-modal'>
                                <div className='sub-title-modal'>
                                    Размерно-весовые характеристики
                                </div>
                            </div>
                            <div className='weigth-length-table'>
                                {this.state.weigthLengthData.length ? (
                                    <div className='row-modal header'>   
                                        <div className="weigth-length-table-one"></div>                             
                                        <div className="weigth-length-table-two">Масса, г</div>
                                        <div className="weigth-length-table-two">Длина, г</div>
                                        {/* <div className="weigth-length-table-two">Наполненность, г</div> */}
                                        <div className="weigth-length-table-two">КУ</div>
                                    </div>
                                ) : (<div> </div>)}
                                {this.state.weigthLengthData.map((item, index) => (
                                    <WeigthLengthRow initValue={item} serial={item.serial || item.serial_number} key={index} changeCallback={(index, row, value) => this.changeWeigthLength(index, row, value)}/>     
                                ))}
                                {(this.state.saveSuccess) ? (
                                    <div>                  
                                    </div>
                                ) : (
                                <div className='row-modal'>  
                                    <div className='add-more' onClick={this.addMoreRow}>
                                        + Добавить еще 
                                    </div>
                                </div>      
                                )}                                            
                            </div>
                            <div className='row-modal wrap'>
                                <Input title='Наполненность кишечника, %' value={this.state.fullnessValue} changeHandler={(e) => this.changeHandler(e, 'fullnessValue')} />
                                <Input title='Отношение кормов' value={this.state.feedRatioValue} changeHandler={(e) => this.changeHandler(e, 'feedRatioValue')} />
                               </div>
                            <div className='row-modal'>                                
                                <div className='sub-title-modal'>
                                    Патогенез
                                </div>                            
                            </div>
                            <div className='row-modal'>
                                <Input title='' value={this.state.pathogenesisValue} changeHandler={(e) => this.changeHandler(e, 'pathogenesisValue')} fullWidth={true}/>
                            </div>
                            <div className='row-modal'>                                
                                <div className='sub-title-modal'>
                                    Заключение
                                </div>                            
                            </div>
                            <div className='row-modal'>
                                <Input title='' value={this.state.conclusionValue} changeHandler={(e) => this.changeHandler(e, 'conclusionValue')} fullWidth={true}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className='row-modal'>
                        <div className='sub-title-modal'>
                            {(this.state.saveSuccess) ? (
                                <div className='succes-block'>
                                    <div className='success-icon'></div>
                                    <div className='success-text'>{this.props.saveSuccessText}</div>                           
                                </div>
                            ) : (
                                <Button title={this.props.buttonText} icon={this.props.buttonIcon}  handler={this.props.isCreate ? () => this.sendReq('create') :  () => this.sendReq('update') } />
                            )}
                        </div>
                    </div>
                </Scrollbar>
           </div>
		);
	}
}
