import React from "react";

import './LoadingPage.css';

export class LoadingPage extends React.Component {
  render() {
    if (this.props.local) {
      return (
        <div className="loading">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      )
    }
    return (
        <section className='wrapper'>
            <div className={this.props.full ? 'full spinner' : 'spinner' }>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            </div>
        </section>
    );
  }
}
