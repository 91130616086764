import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../Elements/Button/Button";

export class AddUserWindow extends React.Component {    
    constructor(props) {
        super(props);
        
        this.selectUser = this.selectUser.bind(this);
    }  

    selectUser(item) {      
        this.props.selectCallback && this.props.selectCallback(item);     
    }

	render() {
		return (
           <div className='research-modal'>                 
                <div className='title-modal'>
                    Список пользователей
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>                    
                    <div className='row-modal'>
                        <div className='input-column'>                    
                            <div className='row-modal wrap'>
                            {this.props.users && this.props.users.map((item, index) => (
                                <Button 
                                    title={item.login} 
                                    key={index} 
                                    id={item.id} 
                                    class='add-user-container'
                                    handler={() => this.selectUser(item)} />                                
                                )
                            )}
                            </div>   
                        </div>
                    </div>          
                </Scrollbar>
           </div>
		);
	}
}
