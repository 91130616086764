import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../Elements/Button/Button";
import { Modal } from "../../Elements/Modal/Modal";
import { Input } from '../../Elements/Input/Input';

export class EditWindow extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.updateHandler = this.updateHandler.bind(this);
    }  

    state = {
        saveSuccess: false,
        errorState: false,
        errorText: '',
        valueNames: []
    }

    componentDidMount() {
       if (this.props.initValue.data && this.props.initValue.data) {
            let valueName = [];
            for (const value in this.props.initValue.data) {
                valueName.push(this.props.initValue.data[value].valueName)
                this.setState( previousState => {
                    previousState[this.props.initValue.data[value].valueName] =this.props.initValue.data[value].value;
                })
            }
            this.setState({
                valueNames: valueName
            })
       }
    }

    updateHandler(e) {
        let args = {'id': this.props.initValue.id};
        for (const value in this.state.valueNames) {
            args[this.state.valueNames[value]] = this.state[this.state.valueNames[value]];
        }   
        this.setState({
            saveSuccess: true
        })
        setTimeout(() => {
            if (this) {
                this.setState({
                    saveSuccess: false
                })
            }
        }, 500)
        this.props.updateCallback && this.props.updateCallback(args)
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

	render() {
		return (
           <div className='research-modal'>               
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}               
                <div className='title-modal'>
                    {this.props.title}
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>
                    {this.props.initValue.data && this.props.initValue.data.map((item, index) => (
                        <div className='row-modal' key={index}>
                            <div className='input-column'>
                                <div className='row-modal wrap'>
                                    {this.state[item.valueName] !== undefined && (
                                        <Input title={item.title} value={this.state[item.valueName]} changeHandler={(e) => this.changeHandler(e, item.valueName)} fullWidth={true} />
                                    )}
                                </div>                 
                            </div>             
                        </div>
                        )
                    )}

                    <div className='row-modal'>                        
                        <div className='input-column'> 
                            {(this.state.saveSuccess) ? (
                                <div className='succes-block'>
                                    <div className='success-icon'></div>
                                    <div className='success-text'>Изменения сохранены</div>                           
                                </div>
                            ) : (
                                <Button title='Сохранить изменения' handler={this.updateHandler} />
                            )}
                        </div>   
                    </div>           
                </Scrollbar>
           </div>
		);
	}
}
