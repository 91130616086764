import React from "react";

import { Table } from "../Elements/Table/Table";
import { Modal } from "../Elements/Modal/Modal";

import { EditWindow } from "./EditWindow";

import { sendRequest } from '../../utils/XHR';
import { APIContext } from '../../utils/APIContext';

export class SeedingPage extends React.Component {      
    static contextType = APIContext;

    constructor(props) {
        super(props);        
        this.tableHeader = [];
        this.tableColumnStyle = {}

        this.getAllPonds = this.getAllPonds.bind(this);        
        this.preparePondsData = this.preparePondsData.bind(this);
        this.calculatePondsTableData = this.calculatePondsTableData.bind(this);

        this.openEditWindow = this.openEditWindow.bind(this);
        this.closeEditWindow = this.closeEditWindow.bind(this);
    }  
    state = {           
        showModal: false,
        fisheriesDataId: null,     
        pondsData: [],
        initValue: []
    }
   
    componentDidMount() {
        this.setState({
            fisheriesDataId: null
        })    
    }

    componentDidUpdate() {        
        if (this.props.fisheriesData && this.state.fisheriesDataId !== this.props.fisheriesData['id']) {            
            this.preparePondsData();
            this.getAllPonds();        
            this.setState({
                fisheriesDataId: this.props.fisheriesData['id']
            })    
        }
    }

    getAllPonds() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.fisheriesData['id']);
        formData.append('seeding', true);
        let response = sendRequest(formData, '/pond/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            for (const index in result) {
                result[index]['fish_count'] = result[index]['fish_count'].toLocaleString();
                result[index]['fish_weight'] = result[index]['fish_weight'].toLocaleString();
                result[index]['sq'] = result[index]['sq'].toLocaleString();
            }   
            this.calculatePondsTableData(result);
        });
    }

    calculatePondsTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                name: data[item]['name'],
                sq: data[item]['sq'],
                weigth: data[item]['fish_weight'],
                weigth_abs: data[item]['weigth_abs'],
                count: data[item]['fish_count'],
                density: data[item]['density'],
                ku: data[item]['ku'],                
                seedingFrom: data[item]['seeding_from'],
                seeding_from_id: data[item]['seeding_from_id'],
            }
            element['change'] = {handler: ((e) => {this.openEditWindow(data[item])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.pondsData)) {
            this.setState({
                pondsData: newData
            })  
        }
    }

    preparePondsData() {        
        this.tableHeader = [
            {name: 'name', text: 'Название'},
            {name: 'sq', text: 'Площадь'},
            {name: 'weigth', text: 'Масса, кг'},
            {name: 'weigth_abs', text: 'Ср. шт. масса рыбы, г'},
            {name: 'count', text: 'Количество штук'},
            {name: 'density', text: 'Плотность, тыс. шт. / га'},
            {name: 'ku', text: 'КУ'},
            {name: 'seedingFrom', text: 'Откуда взято'},
        ];

        this.tableColumnStyle = {
            name: 'one', 
            sq: 'one', 
            weigth: 'one', 
            weigth_abs: 'one', 
            count: 'one', 
            density: 'one',  
            ku: 'one',  
            seedingFrom: 'one', 
        }

        if (this.context.allowEdit) {
            this.tableHeader.push({name: 'change', text: '',  useButton: true, isEdit: true, isEditText: 'Зарыбление' });
            this.tableColumnStyle['change'] = 'one';
        }
    }

    onEditPond(data) {
        this.calculatePondsTableData(data);
        this.props.onChangeSettings();
    }

    openEditWindow(value) {
        this.setState({
            showModal: true,
            initValue: value
        })
    }

    closeEditWindow() {
        this.setState({
            showModal: false,
            initValue: []
        })

    }

    render() {
        if (this.props.activeFisheries === null) {
            return (<div></div>);
        }
        return (                
            <div className='page-container'> 
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeEditWindow} 
                        content={<EditWindow 
                                    id={this.props.fisheriesData['id']}
                                    initValue={this.state.initValue}
                                    editCallback={(data) => this.onEditPond(data)}
                                />} 
                        />
                )}
                <div className='dashboard-row'>
                    <div className='dashboard-title-sub'>Зарыбление прудов</div>
                </div>    
                <div className='content-container'>
                    <Table 
                        headers={this.tableHeader}
                        data={this.state.pondsData} 
                        style={this.tableColumnStyle} 
                        tableHeight={600} 
                        bigContainer={true}
                    />
                </div>
                   
            </div>
        );
    }
}
