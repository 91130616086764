import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../Elements/Button/Button";
import { Modal } from "../../Elements/Modal/Modal";
import { Input } from '../../Elements/Input/Input';
import { Select } from '../../Elements/Select/Select';

import { LoadingPage } from "../../LoadingPage/LoadingPage";

import { sendRequest } from '../../../utils/XHR';
import { validateNotEmpty } from '../../../utils/Validator';

export class CreateWindow extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        
        this.getPondTypeData = this.getPondTypeData.bind(this);
        this.getFishData = this.getFishData.bind(this);
        this.addPond = this.addPond.bind(this);
    }  

    state = {
        saveSuccess: false,
        errorState: false,
        errorText: '',

        nameValue: '',
        sqValue: '',
        typeValue: -1,
        fishValue: -1,

        pondTypeData: null,
        fishTypeData: null
        
    }

    componentDidMount() {
        if (this.props.id) {
            this.getPondTypeData();
            this.getFishData();
        }
    }

    getPondTypeData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/pond_type/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }     
            let newData = [];
            for (const item in result) {
                let element = {
                    id: result[item]['id'],
                    title: result[item]['name'],
                }            
                newData.push(element);           
            }
            this.setState({
                pondTypeData: newData,
                typeValue: 0
            })     
        });
    }

    getFishData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/fish/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            let newData = [];
            for (const item in result) {
                let element = {
                    id: result[item]['id'],
                    title: result[item]['name'],
                }            
                newData.push(element);           
            }
            this.setState({
                fishTypeData: newData,
                fishValue: 0
            })     
        });
    }

    addPond() {
        if (!validateNotEmpty(this.state.nameValue)) {
            this.setState({
                errorText: 'Название не может быть пустым',
                errorState: true
            });
            return;
        }
        
        if (!validateNotEmpty(this.state.sqValue)) {
            this.setState({
                errorText: 'Площадь не может быть пустым',
                errorState: true
            });
            return;
        }
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', this.state.nameValue);
        formData.append('sq', this.state.sqValue);
        formData.append('type_id', this.state.pondTypeData[this.state.typeValue].id);
        formData.append('fish_id', this.state.fishTypeData[this.state.fishValue].id);
        let response = sendRequest(formData, '/pond/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }         
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)   
            this.props.createCallback && this.props.createCallback(result.data);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    selectHandler(_e, stateName, index) {
        this.setState({
            [stateName]: index
        })
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }


	render() {
		return (
           <div className='research-modal'>               
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <div className='title-modal'>
                    Создание нового пруда
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>
                    <div className='row-modal'>
                        <div className='input-column'>
                            <Input title='Название пруда' value={this.state.nameValue} changeHandler={(e) => this.changeHandler(e, 'nameValue')} fullWidth={true} />  
                            <Input title='Площадь' value={this.state.sqValue} changeHandler={(e) => this.changeHandler(e, 'sqValue')} fullWidth={true} />  
                            <div className='row-modal'>
                                <div className='input-column'>
                                    <div className='sub-title'>
                                        Тип пруда
                                    </div>
                                    {this.state.pondTypeData ? (
                                        <Select 
                                            active={this.state.pondTypeData[this.state.typeValue].title} 
                                            data={this.state.pondTypeData} 
                                            handler={(e, index) => this.selectHandler(e, 'typeValue', index)}
                                            class={'full-width-select'}
                                        />     
                                    ) : (<LoadingPage local={true} />)}        
                                </div>
                            </div>
                            <div className='row-modal'>
                                <div className='input-column'>
                                    <div className='sub-title'>
                                        Вид рыбы
                                    </div>
                                    
                                    {this.state.fishTypeData ? (
                                        <Select 
                                            active={this.state.fishTypeData[this.state.fishValue].title} 
                                            data={this.state.fishTypeData} 
                                            handler={(e, index) => this.selectHandler(e, 'fishValue', index)}
                                            class={'full-width-select'}
                                        />             
                                        ) : (<LoadingPage local={true} />)}    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='row-modal'>
                        <div className='sub-title-modal'>
                            {(this.state.saveSuccess) ? (
                                <div className='succes-block'>
                                    <div className='success-icon'></div>
                                    <div className='success-text'>Пруд добавлен</div>                           
                                </div>
                            ) : (
                                <Button title='Добавить пруд' icon='button-plus-icon'  handler={this.addPond} />
                            )}
                        </div>
                    </div>
                </Scrollbar>
           </div>
		);
	}
}
