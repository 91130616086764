import React from "react";

import { Button } from "../Button/Button";

import './Numerator.css';

export class Numerator extends React.Component {       
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
    }  

    changeHandler(e, index) {
        this.props.action && this.props.action(e, index);
    }
    render() {
        return (   
            <div className='numerator-container'>                
                {[...Array(this.props.count)].map((x, index) =>
                    <div className='numerator-button' key={index} onClick={this.props.action}>                    
                        <Button title={index+1} handler={(e) => this.changeHandler(e, index)} class={this.props.current === index && 'invert'}/>
                    </div>
                )}
               
            </div>
        );
    }
}
