import React from "react";

import { Input } from "../../Elements/Input/Input";
import { Button } from "../../Elements/Button/Button";
import { Table } from "../../Elements/Table/Table";
import { Modal } from "../../Elements/Modal/Modal";

import { EditWindow } from "./EditWindow";

import { sendRequest } from '../../../utils/XHR';

const feedTableHeader = [
    {name: 'name', text: 'Название'},
    {name: 'weigth', text: 'Вес'},
    {name: 'price', text: 'Цена'},
    {name: 'change', text: '',  useButton: true, isEdit: true, isEditText: 'Редактировать' },
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Удалить' },
];

const feedTableColumnStyle = {
    name: 'one', 
    weigth: 'one', 
    price: 'one', 
    change: 'one', 
    remove: 'one', 
}

export class Feed extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this); this.openEditWindow = this.openEditWindow.bind(this);
        this.openEditWindow = this.openEditWindow.bind(this);
        this.closeEditWindow = this.closeEditWindow.bind(this);
       
       
        this.getFeedData = this.getFeedData.bind(this);
        this.calculateFeedTableData = this.calculateFeedTableData.bind(this);
        this.addFeedType = this.addFeedType.bind(this);
        this.removeFeedType = this.removeFeedType.bind(this); 
        this.updateFeedType = this.updateFeedType.bind(this);       
  
    }  

    state = {
        errorText: '',
        errorState: false,
        
        saveSuccess: false,

        showModal: false,
        editValue: null,

        feedData: [],
        feedNameValue: '',
        feedWeigthValue: '',
        feedPriceValue: '',
    }

    componentDidMount() {
        if (this.props.id) {
            this.getFeedData();
        }
    }

    getFeedData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/feed/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.calculateFeedTableData(result);
        });
    }

    calculateFeedTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                name: data[item]['name'],
                weigth: data[item]['weigth'],
                price: data[item]['price'],
            }
            const forChange = {
                data: [
                    {
                        title: 'Название',
                        value: data[item]['name'],
                        valueName: 'nameValue'
                    },
                    {
                        title: 'Вес',
                        value: data[item]['weigth'],
                        valueName: 'weigthValue'
                    },
                    {
                        title: 'Цена',
                        value: data[item]['price'],
                        valueName: 'priceValue'
                    }
                ],
                id: data[item]['id']
            }
            element['change'] = {handler: ((e) => {this.openEditWindow(forChange)})} || ''
        
            element['remove'] = {handler: ((e) => {this.removeFeedType(data[item]['id'])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.feedData)) {
            this.setState({
                feedData: newData
            })  
        }
    }

    addFeedType() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', this.state.feedNameValue);
        formData.append('weigth', this.state.feedWeigthValue);
        formData.append('price', this.state.feedPriceValue);
        let response = sendRequest(formData, '/feed/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.calculateFeedTableData(result);
        });
    }

    removeFeedType(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/feed/delete');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }    
            this.props.onChangeSettings();            
            this.calculateFeedTableData(result);
        });
    }

    updateFeedType(args) {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', args.nameValue);
        formData.append('weigth', parseInt(args.weigthValue.replace(/\s/g, '')));
        formData.append('price', parseInt(args.priceValue.replace(/\s/g, '')));
        formData.append('id', args.id);
        let response = sendRequest(formData, '/feed/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)
            this.props.onChangeSettings();            
            this.calculateFeedTableData(result);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }
    
    openEditWindow(value) {
        this.setState({
            showModal: true,
            editValue: value
        })
    }

    closeEditWindow() {
        this.setState({
            showModal: false,
            editValue: null
        })

    }
    
    render() {
        return (                
            <div className='block-container'>
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeEditWindow} 
                        content={<EditWindow 
                                    title='Редактирование Корма'
                                    initValue={this.state.editValue}
                                    updateCallback={(args) => this.updateFeedType(args)}
                                />} 
                        />
                )}
                <div className='dashboard-title-sub'>Корма</div>                    
                <div className='card-container'>                        
                    <Table 
                        headers={feedTableHeader}
                        data={this.state.feedData} 
                        style={feedTableColumnStyle} 
                        tableHeight={300} 
                    />
                </div>
                <div className='fast-add-container'>
                    <Input title='Название' value={this.state.feedNameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'feedNameValue')} />
                    <Input title='Вес' value={this.state.feedWeigthValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'feedWeigthValue')} />
                    <Input title='Цена' value={this.state.feedPriceValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'feedPriceValue')} />
                    {(this.state.saveSuccess) ? (
                        <div className='succes-block'>
                            <div className='success-icon'></div>
                            <div className='success-text'>Корм добавлен</div>                           
                        </div>
                    ) : (
                        <Button title='Добавить корм' icon='button-plus-icon'  handler={() => this.addFeedType()} />
                    )}
                </div>
            </div>
        );
    }
}
