/* Конфиг приложения
 */

const Confing = {
  /* Адрес сервера с API
   */
  URL: "https://fishapi.agrointellect.ru", //"https://localhost:5000"
  // URL: "http://localhost:5000", //"https://localhost:5000"

  /* Копирайт внизу сайта
   */
  copyrightName: "Trial version",

  /* Флаг прерывания сессии по времени
   */
  terminateSession: false,

  /* Время жизни куки в миллисекндах
   */
  coockieTime: 31536000000,

  /* Флаг сохранения выбранной фермы
   */
  saveSelectedFarm: true,

  /* Флаг сохранения настроек моделирования в куке
   */
  saveSelectedModulateValue: true,
};

export default Confing;