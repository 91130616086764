import React from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { Select } from "../Select/Select";

import './Table.css';

export class Table extends React.Component {
    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.syncBodyScroll = this.syncBodyScroll.bind(this);
        this.syncHeadScroll = this.syncHeadScroll.bind(this);
        this.tableHeadRef = React.createRef();
        this.tableBodyRef = React.createRef();
    }  
    state = {
        size: 'normal',
        blockScroll: false
    }
    componentDidMount() {
        if (this.props.size){
            this.setState({
                size: this.props.size
            })
        }
    }  
    clickHandler(e, item) {
        if (item.handler && typeof item.handler === 'function') {
            item.handler(e);
        }
    }
    selectHandler(e, item, index) {
        if (item.selectHandler && typeof item.selectHandler === 'function') {
            item.selectHandler(e, index);
        }
    }
    scrollHandler(curr) {
        if ((curr.clientHeight + curr.scrollTop) === curr.contentScrollHeight) {
            this.props.onScrollEndHandler && this.props.onScrollEndHandler();
        }
        this.syncHeadScroll(curr);
    }
    syncBodyScroll(curr) {
        if (this.state.blockScroll) {
            this.setState({
                blockScroll: false
            })
            return;
        }
        var topPos = this.tableBodyRef.current.getScrollState().scrollTop;
        this.tableBodyRef.current.scrollTo(curr.scrollLeft, topPos)
        this.setState({
            blockScroll: true
        })
    }
    syncHeadScroll(curr) {
        if (this.state.blockScroll) {
            this.setState({
                blockScroll: false
            })
            return;
        }
        this.tableHeadRef.current.scrollTo(curr.scrollLeft, 0)
        this.setState({
            blockScroll: true
        })
    }
    render() {
        return (
            <div className={`${this.props.noCard ? '' : 'card-block card-flex'} table-scroller`}>
                <div className='table-no-scroller'>
                    <Scrollbar noDefaultStyles style={{ width: "100%", height: `35px` }} onScroll={(curr, prev) => this.syncBodyScroll(curr, prev)} ref={this.tableHeadRef}>                 
                        <div className='table-header'>                      
                                {this.props.headers && this.props.headers.map((item, index) => 
                                    <div className={this.props.style[item.name]} key={index}>
                                        {item.text}
                                    </div>
                                )} 
                        </div>
                    </Scrollbar>
                </div>
                
                <div className={this.props.bigContainer ? 'table-body big' : 'table-body'} style={{ maxHeight: `${this.props.tableHeight || 'auto'}px` }}>
                        <Scrollbar noDefaultStyles style={{ width: "100%", height: `${this.props.tableHeight || 310}px` }} onScroll={(curr, prev) => this.scrollHandler(curr, prev)} ref={this.tableBodyRef}>
                        {this.props.data && this.props.data.map((dataItem, dataIndex) => 
                            <div className='table-row' key={dataIndex}>
                                {this.props.headers && this.props.headers.map((item, index) => 
                                    <div className={this.props.style[item.name]} key={index}>
                                        {item.useButton ? (
                                            <div className={item.isEdit ? 'button edit' : 'button'} onClick={(e) => this.clickHandler(e, dataItem[item['name']])} >
                                                { item.isEdit ? (
                                                    <div className='edit-container'>
                                                        <div className={item.isEditIcon || 'editIcon'}></div>
                                                        <div className='editTitle'>{item.isEditText || 'Изменить'}</div>
                                                    </div>
                                                ) : (
                                                    dataItem[item.name]
                                                )}
                                            </div>
                                        ) : (   
                                            item.useSelect ? (
                                                <Select 
                                                    active={dataItem[item.name].name} 
                                                    data={item.selectData} 
                                                    handler={(e, index) => this.selectHandler(e, dataItem[item['name']], index)} 
                                                    class={'small-select'}
                                                />
                                            ) : (
                                                `${dataItem[item.name]}`
                                            )                          
                                        )}
                                        
                                    </div>
                                )}  
                            </div>
                        )}  
                    </Scrollbar>
                </div>
            </div>
        );
    }
}
