import React from "react";

import { Modal } from "../Elements/Modal/Modal";

import { FisheriesName } from './Fisheries/FisheriesName';
import { Users } from './Fisheries/Users';
import { Responsible } from './Fisheries/Responsible';
import { PondType } from './Fisheries/PondType';
import { Feed } from './Fisheries/Feed';
import { Fish } from './Fisheries/Fish';


import './SettingsPage.css';

export class Fisheries extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this); 
    }  

    state = {
        errorText: '',
        errorState: false,
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    render() {
        if (!this.props.fisheriesData) {
            return (<div></div>);
        } 
        return (                
            <div className='page-container settings-page'> 
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <FisheriesName 
                    name={this.props.fisheriesData['name']}
                    id={this.props.fisheriesData['id']}
                    onChangeSettings= {() => this.props.onChangeSettings()}
                    
                />
                <Users 
                    id={this.props.fisheriesData['id']}                     
                    onChangeSettings= {() => this.props.onChangeSettings()}
                />
                <Responsible 
                    id={this.props.fisheriesData['id']}                 
                    onChangeSettings= {() => this.props.onChangeSettings()}
                />               
                <PondType 
                    id={this.props.fisheriesData['id']}
                    onChangeSettings= {() => this.props.onChangeSettings()}
                />
                <Feed 
                    id={this.props.fisheriesData['id']}
                    onChangeSettings= {() => this.props.onChangeSettings()}
                />
                <Fish 
                    id={this.props.fisheriesData['id']}
                    onChangeSettings= {() => this.props.onChangeSettings()}
                />

            </div>
        );
    }
}
