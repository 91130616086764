import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../Elements/Button/Button";
import { Modal } from "../Elements/Modal/Modal";
import { Input } from '../Elements/Input/Input';
import { Select } from '../Elements/Select/Select';


import { LoadingPage } from "../LoadingPage/LoadingPage";

import { sendRequest } from '../../utils/XHR';
import { validateNotEmpty } from '../../utils/Validator';

export class EditWindow extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        
        this.getSeedingFromData = this.getSeedingFromData.bind(this);
        this.getResponsibleData = this.getResponsibleData.bind(this);
        this.updatePond = this.updatePond.bind(this);
    }  

    state = {
        saveSuccess: false,
        errorState: false,
        errorText: '',

        dateValue: '',
        weigthValue: '',
        countValue: '',
        kuValue: '',    
        responsibleValue: -1,
        seedingFromValue: null,    
        
        seedingFromData: null,
        responsibleData: null
    }

    componentDidMount() {
        if (this.props.id) {
            this.getSeedingFromData();
            this.getResponsibleData();
            this.setState({
                weigthValue: this.props.initValue['fish_weight'],
                countValue: this.props.initValue['fish_count'],
                kuValue: this.props.initValue['ku'],
                dateValue: this.props.initValue['date_start'],
            })
        }
    }

    getSeedingFromData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/seeding/from/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            let newData = [];
            let active = 0;
            for (const item in result) {
                let element = {
                    id: result[item]['id'],
                    title: result[item]['name'],
                }            
                newData.push(element);     
                if (result[item]['id'] === this.props.initValue['seeding_from_id']) {
                    active = item;
                }      
            }
            this.setState({
                seedingFromData: newData,
                seedingFromValue: active
            })     
        });
    }
    
    getResponsibleData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/responsible/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            let newData = [];
            let active = 0;
            for (const item in result) {
                let element = {
                    id: result[item]['id'],
                    title: `${result[item]['surname']} ${result[item]['name']} ${result[item]['middlename']}`,
                }            
                newData.push(element);     
                if (result[item]['id'] === this.props.initValue['responsible_id']) {
                    active = item;
                }      
            }
            this.setState({
                responsibleData: newData,
                responsibleValue: active
            })     
        });
    }

    updatePond() {
        if (!validateNotEmpty(this.state.countValue)) {
            this.setState({
                errorText: 'Количество рыбы не может быть пустым',
                errorState: true
            });
            return;
        }
        
        if (!validateNotEmpty(this.state.weigthValue)) {
            this.setState({
                errorText: 'Вес не может быть пустым',
                errorState: true
            });
            return;
        }
        const date = this.state.dateValue ? new Date(this.state.dateValue).toISOString().slice(0, 11) + new Date().toISOString().slice(11) : new Date().toISOString();
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('id', this.props.initValue['id']);
        formData.append('fish_weight', parseFloat((''+this.state.weigthValue).replace(/\s/g, '').replace(/,/g, '.')));
        formData.append('fish_count', parseInt((''+this.state.countValue).replace(/\s/g, '')));        
        formData.append('ku', this.state.kuValue);     
        formData.append('seeding_from_id', this.state.seedingFromData[this.state.seedingFromValue].id);        
        formData.append('responsible_id', this.state.responsibleData[this.state.responsibleValue].id);        
        formData.append('date_start', date);
            
        formData.append('seeding', true);
        let response = sendRequest(formData, '/pond/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }          
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)  
            for (const index in result) {
                result[index]['fish_count'] = result[index]['fish_count'].toLocaleString();
                result[index]['fish_weight'] = result[index]['fish_weight'].toLocaleString();
                result[index]['sq'] = result[index]['sq'].toLocaleString();
            }   
            this.props.editCallback && this.props.editCallback(result);
        });
    }

    changeHandler(e, stateName) {        
        let value = e.target.value;
        const spaceCount = value.match(/([\s]+)/g) ? value.match(/([\s]+)/g).length : 0;
        const replacedString = parseInt(e.target.value.replace(/\s/g, ''));
        if (('' + replacedString).length + spaceCount === e.target.value.length) {
            value = replacedString.toLocaleString();
        }
        
        this.setState({
            [stateName]: value
        });
    }

    selectHandler(_e, stateName, index) {
        this.setState({
            [stateName]: index
        })
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }


	render() {
		return (
           <div className='research-modal'>               
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <div className='title-modal'>
                    Зарыбление пруда "{this.props.initValue.name}"
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>
                    <div className='row-modal'>
                        <div className='input-column'>
                            <Input title='Масса, кг' value={this.state.weigthValue} changeHandler={(e) => this.changeHandler(e, 'weigthValue')} fullWidth={true} />  
                            <Input title='Количество штук' value={this.state.countValue} changeHandler={(e) => this.changeHandler(e, 'countValue')} fullWidth={true} />  
                            <Input title='КУ' value={this.state.kuValue} changeHandler={(e) => this.changeHandler(e, 'kuValue')} fullWidth={true} />  
                        </div>
                        <div className='input-column'>
                            <Input title='Дата зарыбления' type='date' value={this.state.dateValue} changeHandler={(e) => this.changeHandler(e, 'dateValue')} /> 
                        </div>
                    </div>
                    <div className='row-modal'>
                        <div className='input-column'>
                            <div className='sub-title'>
                                Откуда взяли
                            </div>
                            
                            {this.state.seedingFromData ? (
                                <Select 
                                    active={this.state.seedingFromData[this.state.seedingFromValue].title} 
                                    data={this.state.seedingFromData} 
                                    handler={(e, index) => this.selectHandler(e, 'seedingFromValue', index)}
                                    class={'full-width-select'}
                                />             
                                ) : (<LoadingPage local={true} />)}    
                        </div>
                    </div>
                    <div className='row-modal'>
                        <div className='input-column'>
                            <div className='sub-title'>
                                Отвественный
                            </div>
                            
                            {this.state.responsibleData ? (
                                <Select 
                                    active={this.state.responsibleData[this.state.responsibleValue].title} 
                                    data={this.state.responsibleData} 
                                    handler={(e, index) => this.selectHandler(e, 'responsibleValue', index)}
                                    class={'full-width-select'}
                                />             
                                ) : (<LoadingPage local={true} />)}    
                        </div>
                    </div>
                    <div className='row-modal'>
                        <div className='sub-title-modal'>
                            {(this.state.saveSuccess) ? (
                                <div className='succes-block'>
                                    <div className='success-icon'></div>
                                    <div className='success-text'>Зарыбление произведененно</div>                           
                                </div>
                            ) : (
                                <Button title='Сохранить' icon=' '  handler={this.updatePond} />
                            )}
                        </div>
                    </div>
                </Scrollbar>
           </div>
		);
	}
}
