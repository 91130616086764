import React from "react";

import { Modal } from "../Elements/Modal/Modal";

import { SeedingFrom } from "./Ponds/SeedingFrom";
import { Pond } from "./Ponds/Pond";

import './SettingsPage.css';


export class Ponds extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
          
    }  

    state = {
        errorText: '',
        errorState: false,
      
    }

      
    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }


    render() {
        if (!this.props.fisheriesData) {
            return (<div></div>);
        } 
        return (                
            <div className='page-container settings-page'> 
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <Pond     
                    id={this.props.fisheriesData['id']}
                    onChangeSettings= {() => this.props.onChangeSettings()}
                />
                <SeedingFrom 
                    id={this.props.fisheriesData['id']}
                    onChangeSettings= {() => this.props.onChangeSettings()}
                    />
            </div>
        );
    }
}
