import React from "react";

import './Card.css';

export class Card extends React.Component {    
    constructor(props) {
        super(props);
        this.cardHandler = this.cardHandler.bind(this);
    }

    state = {
        size: 'normal',
        clickable: ''
    }

    blockSize = {
        normal: 'card-normal',
        big: 'card-big',
        flex: 'card-flex',
        'flex-big': 'card-flex big'
    }

    componentDidMount() {
        if (this.props.size) {
            this.setState({
                size: this.props.size
            })
        }
        if (this.props.clickable) {
            this.setState({
                clickable: 'clickable'
            })
        }
    }  

    cardHandler(e) {
        if (this.props.handler) {
            this.props.handler(e, this.props.id)
        }
    }
    
    render() {
        return (
            <div className={`${this.blockSize[this.state.size]} ${this.state.clickable} ${this.props.customTitle && 'custom'} card-block ${this.props.status}`} onClick={this.cardHandler}>
                {this.props.showBG && (
                    <div className='card-graph'>
                        <div className='empty blue'>                        
                        </div>
                    </div>
                )}
                <div className="pre-title">{this.props.preName}</div>  
                {this.props.baloonText ? (              
                    <div className="baloon-container">
                        <div className="title">{this.props.name}</div>
                        {this.props.baloonText && (
                            <div className="baloon">{this.props.baloonText}</div>
                        )}
                    </div>
                ) : (      
                    <div>
                        <div className="title">{this.props.name}</div>
                        <div className="sub-title">{this.props.subName}</div>
                    </div>
                )}
            </div>
        );
    }
}
