import React from "react";

import { Input } from "../../Elements/Input/Input";
import { Button } from "../../Elements/Button/Button";
import { Table } from "../../Elements/Table/Table";
import { Modal } from "../../Elements/Modal/Modal";

import { EditWindow } from "./EditWindow";

import { sendRequest } from '../../../utils/XHR';

const seedingFromTypeTableHeader = [
    {name: 'name', text: 'Название'},
    {name: 'change', text: '',  useButton: true, isEdit: true, isEditText: 'Редактировать' },
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Удалить' },
];

const seedingFromTypeTableColumnStyle = {
    name: 'one', 
    change: 'one', 
    remove: 'one', 
}

export class SeedingFrom extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.openEditWindow = this.openEditWindow.bind(this);
        this.closeEditWindow = this.closeEditWindow.bind(this);
       
        this.getSeedingFromData = this.getSeedingFromData.bind(this);
        this.calculateSeedingFromTableData = this.calculateSeedingFromTableData.bind(this);
        this.addSeedingFromType = this.addSeedingFromType.bind(this);
        this.removeSeedingFromType = this.removeSeedingFromType.bind(this);   
        this.updateSeedingFromType = this.updateSeedingFromType.bind(this);       
  
    }  

    state = {
        errorText: '',
        errorState: false,
        
        saveSuccess: false,

        showModal: false,
        editValue: null,

        seedingFromData: [],
        seedingFromNameValue: '',
    }

    componentDidMount() {
        if (this.props.id) {
            this.getSeedingFromData();
        }
    }

    getSeedingFromData() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/seeding/from/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }            
            this.calculateSeedingFromTableData(result);
        });
    }

    calculateSeedingFromTableData(data) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                name: data[item]['name'],
            }
            const forChange = {
                data: [
                    {
                        title: 'Название',
                        value: data[item]['name'],
                        valueName: 'nameValue'
                    }
                ],
                id: data[item]['id']
            }
            element['change'] = {handler: ((e) => {this.openEditWindow(forChange)})} || ''
            
            element['remove'] = {handler: ((e) => {this.removeSeedingFromType(data[item]['id'])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.seedingFromData)) {
            this.setState({
                seedingFromData: newData
            })  
        }
    }

    addSeedingFromType() {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', this.state.seedingFromNameValue);
        let response = sendRequest(formData, '/seeding/from/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }       
            this.props.onChangeSettings();        
            this.calculateSeedingFromTableData(result);
        });
    }

    removeSeedingFromType(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.props.id);
        let response = sendRequest(formData, '/seeding/from/delete');
        response.then((result) => {
            const error = result.error;              
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }           
            this.props.onChangeSettings();        
            this.calculateSeedingFromTableData(result);
        });
    }

    updateSeedingFromType(args) {
        let formData = new FormData();
        formData.append('fisheries_id', this.props.id);
        formData.append('name', args.nameValue);
        formData.append('id', args.id);
        let response = sendRequest(formData, '/seeding/from/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }  
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)
            this.props.onChangeSettings();             
            this.calculateSeedingFromTableData(result);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    openEditWindow(value) {
        this.setState({
            showModal: true,
            editValue: value
        })
    }

    closeEditWindow() {
        this.setState({
            showModal: false,
            editValue: null
        })

    }

    render() {
        return (               
            <div className='block-container'>
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeEditWindow} 
                        content={<EditWindow 
                                    title='Редактирование'
                                    initValue={this.state.editValue}
                                    updateCallback={(args) => this.updateSeedingFromType(args)}
                                />} 
                        />
                )}
                <div className='dashboard-title-sub'>Откуда взяли</div>                    
                <div className='card-container'>                        
                    <Table 
                        headers={seedingFromTypeTableHeader}
                        data={this.state.seedingFromData} 
                        style={seedingFromTypeTableColumnStyle} 
                        tableHeight={300} 
                    />
                </div>
                <div className='fast-add-container'>
                    <Input title='Название' value={this.state.seedingFromNameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'seedingFromNameValue')} />
                    {(this.state.saveSuccess) ? (
                        <div className='succes-block'>
                            <div className='success-icon'></div>
                            <div className='success-text'>Успешно добавлено</div>                           
                        </div>
                    ) : (
                        <Button title='Добавить' icon='button-plus-icon'  handler={() => this.addSeedingFromType()} />
                    )}</div>
            </div>
        );
    }
}
