import React from "react";

import './Input.css';

export class Input extends React.Component {    
	render() {
		return (
            <div className={this.props.fullWidth ? 'big input-container' : 'input-container'}>
                <div className='input-title'>
                    {this.props.title}
                </div>                        
                <div className='input-content'>
                    {this.props.noInput ? 
                        <div className={this.props.fullWidth ? 'big' : ''}>{this.props.value}</div> : 
                        <input className={this.props.fullWidth ? 'big' : ''}
                            type={this.props.type || 'text'} 
                            placeholder={this.props.placeholder} 
                            value={this.props.value} 
                            onChange={this.props.changeHandler} 
                        />
                    }
                </div>
        </div>
		);
	}
}
