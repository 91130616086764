import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import { Input } from '../../Input/Input';
import { Select } from '../../Select/Select';

import { strictDate } from '../../../../utils/Common';
import { sendRequest } from '../../../../utils/XHR';

import './CreateWindow.css';

export class CreateWaterWindow extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.sendReq = this.sendReq.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
    }  

    state = {
        saveSuccess: false,
        errorState: false,
        errorText: '',
        dateValue: '',
        oxygenValue: '',
        waterValue: '',
        airValue: '',
        researchId: 0
    }

    componentDidMount() {
        if (this.props.initValue){
            this.setState({               
                dateValue: strictDate(this.props.initValue['date']),
                oxygenValue: this.props.initValue['oxygen'],
                waterValue: this.props.initValue['waterTemperature'],
                airValue: this.props.initValue['airTemperature'],
                researchId: this.props.initValue['id'],
            })
        }
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    prepareDataForRequest() {        
        let formData = new FormData();
        const date = this.state.dateValue ? new Date(this.state.dateValue).toISOString().slice(0, 11) + new Date().toISOString().slice(11) : new Date().toISOString();
        formData.append('fisheries_id', this.props.fisheriesId || 0);
        formData.append('pond_id', this.props.pondId || 0);
        formData.append('water_temperature', this.state.waterValue || 0);
        formData.append('air_temperature', this.state.airValue || 0);
        formData.append('oxygen', this.state.oxygenValue || 0);
        formData.append('oxygen', this.state.oxygenValue || 0);
        formData.append('date', date);
        
        return formData;
    }    

    sendReq(method) {       
        let formData = this.prepareDataForRequest();
        if (method ==='update') {
            formData.append('id', this.state.researchId);
        }
        let response = sendRequest(formData, '/temperature/' + method);
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }
            this.setState({
                saveSuccess: true
            })
            this.props.createCallback && this.props.createCallback();
        });        
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }


	render() {
		return (
           <div className='research-modal'>               
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                <div className='title-modal'>
                        {this.props.windowTitle}
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>
                    <div className='row-modal'>
                        <div className='input-column'>
                            {this.props.useSelectPond && (
                                <div className='row-modal'>                                    
                                    <div className='sub-title-modal'>
                                        <Select active={this.props.pondName} data={this.props.pondsData} handler={this.props.changePond} />
                                    </div>   
                                </div>   
                            )}
                            <div className='row-modal'>
                                <Input title='Дата исследования' type='date' value={this.state.dateValue} changeHandler={(e) => this.changeHandler(e, 'dateValue')}/> 
                            </div>    
                           
                            <div className='row-modal wrap'>
                                <Input title='Кислород' value={this.state.oxygenValue} changeHandler={(e) => this.changeHandler(e, 'oxygenValue')} />
                                <Input title='Температура воды' value={this.state.waterValue} changeHandler={(e) => this.changeHandler(e, 'waterValue')} />
                                <Input title='Температура воздуха' value={this.state.airValue} changeHandler={(e) => this.changeHandler(e, 'airValue')} />
                            </div>
                        </div>
                    </div>
                    
                    <div className='row-modal'>
                        <div className='sub-title-modal'>
                            {(this.state.saveSuccess) ? (
                                <div className='succes-block'>
                                    <div className='success-icon'></div>
                                    <div className='success-text'>{this.props.saveSuccessText}</div>                           
                                </div>
                            ) : (
                                <Button title={this.props.buttonText} icon={this.props.buttonIcon}  handler={this.props.isCreate ? () => this.sendReq('create') :  () => this.sendReq('update') } />
                            )}
                        </div>
                    </div>
                </Scrollbar>
           </div>
		);
	}
}
