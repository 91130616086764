import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../Elements/Button/Button";
import { Modal } from "../../Elements/Modal/Modal";
import { Input } from '../../Elements/Input/Input';
import { Table } from "../../Elements/Table/Table";

import { AddUserWindow } from "./AddUserWindow";

import { sendRequest } from '../../../utils/XHR';

const roleList = [
    {title : 'Администартор', level: 3},
    {title :'Модератор', level: 2},
    {title :'Пользователь', level: 1}
]
const tableHeader = [
    {name: 'login', text: 'Пользователь'},
    {name: 'role', text: 'Роль', useSelect: true, selectData: roleList},    
    {name: 'remove', text: '',  useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Удалить' },
];
const tableColumnStyle = {
    login: 'one', 
    role: 'one', 
    remove: 'one', 
}
export class AboutFisheriesWindow extends React.Component {    
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.updateFisheries = this.updateFisheries.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.calculateTableData = this.calculateTableData.bind(this);
        this.openAddUserWindow = this.openAddUserWindow.bind(this);
        this.closeAddUserWindow = this.closeAddUserWindow.bind(this);
        this.removeUserFromFisheries = this.removeUserFromFisheries.bind(this);
        this.setUserRigths = this.setUserRigths.bind(this);
    }  

    state = {
        saveSuccess: false,
        errorState: false,
        errorText: '',
        nameValue: '',
        idValue: '',
        usersData: [],
        showModal: false,
        users: []
    }

    componentDidMount() {
        this.calculateTableData(this.props.userByFisheries);
        this.setState({
            idValue: this.props.initValue['id'],
            nameValue: this.props.initValue['name'],
        })
    }

    updateFisheries() {       
        let formData = new FormData();
        formData.append('id', this.state.idValue);
        formData.append('name', this.state.nameValue);
        let response = sendRequest(formData, '/fisheries/update');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true
                });
                return;
            }
            this.setState({
                saveSuccess: true
            })
            setTimeout(() => {
                if (this) {
                    this.setState({
                        saveSuccess: false
                    })
                }
            }, 500)
            this.props.updateCallback && this.props.updateCallback(this.state.idValue, this.state.nameValue);
        });        
    }

    calculateTableData(data) {        
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                login: data[item]['user_name']
            }            
            element['role'] = {name: data[item]['user_level'], selectHandler: ((_e, index) => {this.setUserRigths(data[item]['user_id'], roleList[index].level)})};
            element['remove'] = {handler: ((e) => {this.removeUserFromFisheries(data[item]['id'])})} || ''

            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.usersData)) {           
            this.setState({
                usersData: newData
            })  
        }
    }

    selectCallback(item) {
        let formData = new FormData();
        formData.append('user_id', item.id);        
        formData.append('fisheries_id', this.state.idValue);
        let response = sendRequest(formData, '/admin/create');
        response.then((result) => {
            const error = result.error;  
            
            this.closeAddUserWindow();
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }                
            this.calculateTableData(result);
        });
    }

    setUserRigths(userId, userLevel) {
        let formData = new FormData();
        formData.append('user_id', userId);        
        formData.append('user_level', userLevel);              
        formData.append('fisheries_id', this.state.idValue);      
        let response = sendRequest(formData, '/admin/update-by/user');
        response.then((result) => {
            const error = result.error;  
            
            this.closeAddUserWindow();
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }                
            this.calculateTableData(result);
        });
    }

    removeUserFromFisheries(id) {
        let formData = new FormData();
        formData.append('id', id);   
        formData.append('fisheries_id', this.state.idValue);
        let response = sendRequest(formData, '/admin/delete');
        response.then((result) => {
            const error = result.error;  
            
            this.closeAddUserWindow();
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }                
            this.calculateTableData(result);
        });
    }

    openAddUserWindow() {
        let formData = new FormData();
        let response = sendRequest(formData, '/user/get-all');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }                
            this.setState({
                showModal: true,
                users: result
            })
        });
    }

    closeAddUserWindow() {
        this.setState({
            showModal: false,
            users: []
        })
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

	render() {
		return (
           <div className='research-modal'>               
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeAddUserWindow} 
                        content={<AddUserWindow 
                                    users={this.state.users}
                                    selectCallback={(item) => this.selectCallback(item)}
                                />} 
                        />
                )}
                <div className='title-modal'>
                    Подробнее о хозяйстве
                </div>
                <Scrollbar noDefaultStyles style={{ width: "825px", height: "600px" }}>
                    <div className='row-modal'>
                        <div className='input-column'>
                            <div className='row-modal'>
                                <Input title='Название хозяйства' value={this.state.nameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'nameValue')}/>
                            </div>                   
                        </div>

                        <div className='input-column'>                    
                            <div className='row-modal'>
                                {(this.state.saveSuccess) ? (
                                    <div className='succes-block'>
                                        <div className='success-icon'></div>
                                        <div className='success-text'>Изменено</div>                           
                                    </div>
                                ) : (
                                    <Button title='Переименовать' handler={() => this.updateFisheries()} />
                                )}
                                
                            </div>   
                        </div>
                    </div>
                    <div className='row-modal'>
                        <div className='input-column'>
                            <div className='sub-title-modal'>
                                Список пользователей
                            </div>
                        </div>
                        <div className='input-column'>                    
                            <div className='row-modal'>
                                <Button title=' пользователь' icon='button-plus-icon' handler={() => this.openAddUserWindow()} />                                
                            </div>   
                        </div>
                    </div>                    
                                        
                    <div className='row-modal'>
                        
                    <div className='input-column'>   
                            <div className='modal-table-container'>
                                <Table 
                                    headers={tableHeader}
                                    data={this.state.usersData} 
                                    style={tableColumnStyle} 
                                    tableHeight={350} 
                                    bigContainer={true}
                                    noCard={true}
                                />
                            </div>
                        </div>
                    </div>           
                </Scrollbar>
           </div>
		);
	}
}
