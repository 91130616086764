import React from "react";

import { Button } from "../Elements/Button/Button";
import { Modal } from "../Elements/Modal/Modal";
import { Input } from '../Elements/Input/Input';
import { Table } from "../Elements/Table/Table";

import { AboutFisheriesWindow } from "./Fisheries/AboutFisheriesWindow";

import { sendRequest } from '../../utils/XHR';
import { validateNotEmpty } from '../../utils/Validator';

import './AdminPage.css'

const tableHeader = [
    {name: 'name', text: 'Название'},
    // {name: 'usersCount', text: 'Количество пользователей'},
    {name: 'change', text: '', useButton: true, isEdit: true, isEditIcon: ' ', isEditText:'Подробнее' }
];
const tableColumnStyle = {
    name: 'one', 
    usersCount: 'two', 
    change: 'three',
}

export class AdminFisheriesPage extends React.Component {      
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.getFisheriesData = this.getFisheriesData.bind(this);
        this.calculateTableData = this.calculateTableData.bind(this);
        this.resetErrorState = this.resetErrorState.bind(this);
        this.openAboutWindow = this.openAboutWindow.bind(this);
        this.closeAboutWindow = this.closeAboutWindow.bind(this);
    }
    state = {
        fisheriesData: [],
        fisheriesDataLast: [],
        userByFisheries: [],
        waitXHR: false,
        nameValue: '',
        errorText: '',
        errorState: false,
        offset: 0,
        showModal: false,
        aboutValue: null
    } 
    componentDidMount() {
        this.getFisheriesData(false);
    }

    getFisheriesData(needMerge) {
        if (this.state.offset < 0) {
            return;
        }
        let formData = new FormData();
        let response = sendRequest(formData, '/fisheries/get-all');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                    waitXHR: false
                });
                return;
            }                
            this.setState({
                waitXHR: false,
                offset: this.state.offset + result.length
            });
            this.calculateTableData(result, needMerge);
        });
    }

    calculateTableData(data, needMerge, force) {
        let newData = [];
        for (const item in data) {
            let element = {
                id: data[item]['id'],
                userId: data[item]['user_id'],
                fisheriesId: data[item]['fisheries_id'],
                name: data[item]['name'],
                usersCount: 0,
            }
            element['change'] = {handler: ((e) => {this.openAboutWindow(data[item])})} || ''
            
            newData.push(element);           
        }
        if (JSON.stringify(newData) !== JSON.stringify(this.state.fisheriesDataLast) || force) {
            let fullData = newData;
            if (needMerge) {
                fullData = this.state.fisheriesData;
                fullData = fullData.concat(newData);
            }
            this.setState({
                fisheriesData: fullData,
                fisheriesDataLast: newData
            })  
            return;  
        }
        if (needMerge) {
            this.setState({
                offset: -1
            })  
        }
    }

    createFisheries() {        
        if (!validateNotEmpty(this.state.nameValue)) {
            this.setState({
                errorText: 'Название не может быть пустым',
                errorState: true
            });
            return;
        }
        let formData = new FormData();
        formData.append('name', this.state.nameValue);
        let response = sendRequest(formData, '/fisheries/create');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                });
                return;
            }   
            this.setState({
                nameValue: ''
            });
            this.getFisheriesData(true);
        });
    }

    changeHandler(e, stateName) {
        this.setState({
            [stateName]: e.target.value
        });
    }

    openAboutWindow(value) {
        let formData = new FormData();
        formData.append('fisheries_id', value['id']);
        let response = sendRequest(formData, '/admin/get');
        response.then((result) => {
            const error = result.error;  
            if (error) {
                this.setState({
                    errorText: error,
                    errorState: true,
                    waitXHR: false
                });
                return;
            }                
            this.setState({
                showModal: true,
                aboutValue: value,
                userByFisheries: result
            })
        });
    }

    closeAboutWindow() {
        this.setState({
            showModal: false,
            aboutValue: null
        })
    }

    resetErrorState() {
        this.setState({
            errorState: false
        });
    }

    updateCallback(id, name) {
        const newData = [...this.state.fisheriesData];
        for (const index in newData) {
            if (newData[index]['id'] === id) {
                newData[index]['name'] = name;
                break;
            }
        }
        this.calculateTableData(newData, false, true);
    }

    render() {        
        return (                
            <div className='page-container admin-page'> 
                {this.state.errorState && <Modal text={this.state.errorText} status="error" onClose={this.resetErrorState}/>}
                {this.state.showModal && (
                    <Modal 
                        onClose={this.closeAboutWindow} 
                        content={<AboutFisheriesWindow 
                                    initValue={this.state.aboutValue}
                                    userByFisheries={this.state.userByFisheries}
                                    updateCallback={(id, name) => this.updateCallback(id, name)}
                                />} 
                        />
                )}
                <div className='dashboard-title'>
                    Управление хозяйствами
                </div>
                <div className='block-container'>
                    <div className='dashboard-title-sub'>Список хозяйств</div>                    
                    <div className='card-container'>
                        <Table 
                            headers={tableHeader}
                            data={this.state.fisheriesData} 
                            style={tableColumnStyle} 
                            tableHeight={580} 
                            bigContainer={true}
                            onScrollEndHandler={() => this.getFisheriesData(true)}
                        />                       
                    </div>
                    <div className='fast-add-container'>
                        <Input title='Название хозяйства' value={this.state.nameValue} fullWidth={true} changeHandler={(e) => this.changeHandler(e, 'nameValue')} />                        
                        <Button title='Добавить хозяйство' icon='button-plus-icon' handler={() => this.createFisheries()} />
                    </div>
                </div>
            </div>
        );
    }
}
